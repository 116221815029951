import React from "react";
import { PLVCL } from "../components/Layout/PLVCL";
import { PreBookingSystem } from '../components/BookingSystem/PreBookingSystem';
import { NoEntranceTestRequired } from '../components/BookingSystem/NoEntranceTestRequiredBookingSystem';
import { PreBookingParameterValidation } from "../components/ParameterValidation/PreBookingParameterValidation";
export class NoEntranceTestRequiredBookingPage extends React.Component {

    render = () => {
        return (
            <PLVCL 
                heading="Dr. Du Education Enrolment" 
                text="Based on your course selections, no entrance test is required and you may enrol directly. Please complete the registration form"
                >
                <PreBookingParameterValidation>
                    <NoEntranceTestRequired />
                </PreBookingParameterValidation>
            </PLVCL>
        );
    }
}
