import { useLocation } from "react-router";
import { RightOuterLayer, MiddleSection, MiddleSectionHeader, BottomSection } from "../FormComponents/FormSections";
import { PreviousButton, NextButton } from "../FormComponents/Buttons";
import { useNavigate } from "react-router";
import { INTERNAL_ENTRANCE_TEST_BOOKING_FORM, PREBOOKING_FORM } from "../../routes/routes";
import React from "react";
import { FormStage } from "../FormComponents/FormStage";
import { StudentSection } from '../FormSections/StudentSection';
import { SchoolSection } from "../FormSections/SchoolSection";
import { GuardianFormSection } from "../FormSections/GuardianFormSection";
import { NoEntranceTestBookingSection } from "../FormSections/NoEntranceTestBookingSection";
import { ConfirmationSection } from "../FormSections/ConfirmationSection";
import { ConfirmationInfo } from '../../models/ConfirmationInfo';
import { GuardianInfo, GuardianInfoValidator } from "../../models/GuardianInfo";
import { SchoolInfo, SchoolInfoValidator } from "../../models/SchoolInfo";
import { StudentInfo, StudentInfoValidator } from "../../models/StudentInfo";
import { NoEntranceTestBookingInfo } from "../../models/NoEntranceTestBookingInfo";
import entryTestClient from "../../client/EntryTestClient";
import { createStudentRecordForNoEntranceTestFromInterface } from "../../utils/combineInterfaceUtils";
import { CreateStudentWithNoEntranceTest } from "../../models/CreateStudentWithNoEntranceTest";
import { QuarantineStudentInfo } from "../../models/QuarantineStudentInfo";
import Swal from 'sweetalert2';


export const NoEntranceTestRequired = (props: any) => {
    // Used to set the stage of the form
    const [formStage, setFormStage] = React.useState(FormStage.STUDENT)

    const location = useLocation();
    const navigate = useNavigate();
    const Params = new URLSearchParams(location.search);
    
    // Subjects obtained through url parameters
    const subjectOne = Params.get("subjectOne") 
    const subjectTwo = Params.get("subjectTwo")
    const subjectThree = Params.get("subjectThree")
    const subjectFour = Params.get("subjectFour")
    const internal = Params.get("internal")
    
    // For the student section of the form
    const [studentInfo, setStudentInfo] = React.useState<StudentInfo>({
        name: '',
        lastName: '',
        gender: '',
        dateOfBirth: '',
        address: '',
        suburb: '',
        postcode: '',
        phoneNumber: '',
    })
    const [studentFormComplete, setStudentFormComplete] = React.useState(false)

    // For the school section of the form
    const [schoolInfo, setSchoolInfo] = React.useState<SchoolInfo>({
        school: '',
        year: 'Select Current Year',
        name: '',
        otherSchoolName: '',
        number: ''
    })
    const [schoolFormComplete, setSchoolFormComplete] = React.useState(false)

    // For the guardian section of the form
    const [guardianInfo, setGuardianInfo] = React.useState<GuardianInfo>({
        name: '',
        familyName: '',
        number: '',
        email: '',
        relationship: 'Select Option',
        relationshipDetails: '',
        gender: ''
    });
    const [guardianFormComplete, setGuardianFormComplete] = React.useState(false);


    // For the booking section of the form
    const [bookingInfo, setBookingInfo] = React.useState<NoEntranceTestBookingInfo>({
        numberOne: '',
        relationshipOne: '',
        otherOne: '',
        numberTwo: '',
        relationshipTwo: '',
        otherTwo: ''
    })
    const [bookingFormComplete, setBookingFormComplete] = React.useState(false)

    // For the confirmation section of the form
    const [confirmationInfo, setConfirmationInfo] = React.useState<ConfirmationInfo>({
        subjectOne: subjectOne,
        subjectTwo: subjectTwo,
        subjectThree: subjectThree,
        subjectFour: subjectFour,
        subjectConfirmation: false,
        studentConfirmation: false,
        parentConfirmation: false,
        contactConfirmation: false
    })
    const [confirmationFormComplete, setConfirmationFormComplete] = React.useState(false)
    
    const [studentInfoValidator, setStudentInfoValidator] = React.useState<StudentInfoValidator>({
        name: undefined,
        lastName: undefined,
        gender: undefined,
        dateOfBirth: undefined,
        address: undefined,
        suburb: undefined,
        postcode: undefined,
        phoneNumber: undefined
    })

    const studentFormStage = () => {
        const handleStudentButton = () => {
            setStudentInfoValidator((prevState: StudentInfoValidator) => ({
                ...prevState,
                name: undefined,
                lastName: undefined,
                gender: undefined,
                dateOfBirth: undefined,
                address: undefined,
                suburb: undefined,
                postcode: undefined,
                phoneNumber: undefined
            }));

            if (studentFormComplete) {
                setFormStage(FormStage.SCHOOL)
            } else {
                // Define a mapping of field names to their validation messages
                const fieldValidations: Record<keyof StudentInfo, string> = {
                    name: "Please enter student given name",
                    lastName: "Please enter student family name",
                    gender: "Please select a gender",
                    dateOfBirth: "Please select a date of birth",
                    address: "Please enter address",
                    suburb: "Please select a suburb",
                    postcode: "Please enter a postcode",
                    phoneNumber: "Please enter valid student phone Number"
                };

                Object.entries(fieldValidations).forEach(([field, message]) => {
                    if (studentInfo[field as keyof StudentInfo].trim() === '') { // Checks for empty strings
                        setStudentInfoValidator((prevState: StudentInfoValidator) => ({
                            ...prevState,
                            [field]: message
                        }));
                    }
                });
            }
        }

        return (
            <div>
                <RightOuterLayer progress={34}>
                    <MiddleSection>
                        <div>
                            <MiddleSectionHeader>
                                <strong>2/6</strong>Please fill out your student details
                            </MiddleSectionHeader>
                            <StudentSection
                                entranceTestInfo={studentInfo}
                                consume={(e: StudentInfo) => {setStudentInfo(e)}}
                                checkForCompletion={(b: boolean) => setStudentFormComplete(b)}
                                studentInfoValidator={studentInfoValidator}
                            />
                        </div>
                    </MiddleSection>
                    <BottomSection>
                        <i>Note: All fields are compulsory unless marked optional.</i>
                        <br />
                        <br />
                        <PreviousButton text="Previous" consume={() => {
                            if (internal === "true") {
                                navigate(INTERNAL_ENTRANCE_TEST_BOOKING_FORM)
                            } else {
                                navigate(PREBOOKING_FORM)
                            }
                        }}  />
                        <NextButton text="Next" consume={() => {handleStudentButton()}} />
                    </BottomSection>
                </RightOuterLayer>
            </div>
        )
    }
    
    
    const [schoolInfoValidator, setSchoolInfoValidator] = React.useState<SchoolInfoValidator>({
        school: undefined,
        otherSchoolName: undefined,
        year: undefined,
        name: undefined,
        number: undefined
    })
    const schoolFormStage = () => {
        const handleSchoolButton = () => {
            setSchoolInfoValidator((prevState: SchoolInfoValidator) => ({
                ...prevState,
                school: undefined,
                otherSchoolName: undefined,
                year: undefined,
                name: undefined,
                number: undefined
            }));

            if (schoolFormComplete) {
                setFormStage(FormStage.GUARDIAN)
            } else {
                // Define a mapping of field names to their validation messages
                const fieldValidations: Record<keyof SchoolInfo, string> = {
                    school: "Please select a school",
                    year: "Please select student grade",
                    otherSchoolName: "Please enter other school name",
                    name: "Please enter school card name",
                    number: "Please enter school card number (32 character limit)"
                };

                Object.entries(fieldValidations).forEach(([field, message]) => {
                    const value = schoolInfo[field as keyof SchoolInfo].trim()
                    if (value === '' || (value === 'Select Current Year' && field === 'year') || (field === 'number' && value.length > 32)) { // Checks for empty strings
                        setSchoolInfoValidator((prevState: SchoolInfoValidator) => ({
                            ...prevState,
                            [field]: message
                        }));
                    }
                });
            }
        }
        return (
            <div>
                <RightOuterLayer progress={50}>
                    <MiddleSection>
                        <div>
                            <MiddleSectionHeader>
                                <strong>3/6</strong>Please fill out your school details
                            </MiddleSectionHeader>
                            <SchoolSection 
                                schoolInfo={schoolInfo}
                                consume={(val: SchoolInfo) => {setSchoolInfo(val)}}
                                checkForCompletion={(b: boolean) => setSchoolFormComplete(b)}
                                schoolInfoValidator={schoolInfoValidator}
                            />
                        </div>
                    </MiddleSection>
                    <BottomSection>
                        <i>Note: All fields are compulsory unless marked optional.</i>
                        <br />
                        <br />
                        <PreviousButton text="Previous" consume={() => setFormStage(FormStage.STUDENT)}  />
                        <NextButton text="Next" consume={() => {handleSchoolButton()}} />
                    </BottomSection>
                </RightOuterLayer>
            </div>
        )
    }


    const [guardianInfoValidator, setGuardianInfoValidator] = React.useState<GuardianInfoValidator>({
        name: undefined,
        familyName: undefined,
        number: undefined,
        gender: undefined,
        email: undefined,
        relationship: undefined,
        relationshipDetails: undefined
    })

    const guardianFormStage = () => {
        const handleGuardianButton = () => {
            setGuardianInfoValidator((prevState: GuardianInfoValidator) => ({
                ...prevState,
                name: undefined,
                familyName: undefined,
                number: undefined,
                gender: undefined,
                email: undefined,
                relationship: undefined,
                relationshipDetails: undefined
            }));

            if (guardianFormComplete) {
                setFormStage(FormStage.CONFIRMATION)
            } else {
                // Define a mapping of field names to their validation messages
                const fieldValidations: Record<keyof GuardianInfo, string> = {
                    name: "Please enter guardian given name",
                    familyName: "Please enter guardian family name",
                    number: "Please enter guardian number",
                    gender: "Please select a gender",
                    email: "Please enter guardian email",
                    relationship: "Please select guardian relationship to student",
                    relationshipDetails: "Please enter relationship details",
                };

                Object.entries(fieldValidations).forEach(([field, message]) => {
                    const value = guardianInfo[field as keyof GuardianInfo].trim();
                    if (value === '' || (field === 'relationship' && value === 'Select Option')) { // Checks for empty strings
                        setGuardianInfoValidator((prevState: GuardianInfoValidator) => ({
                            ...prevState,
                            [field]: message
                        }));
                    }
                });
            }
        }
        return (
            <div>
                <RightOuterLayer progress={66}>
                    <MiddleSection>
                        <div>
                            <MiddleSectionHeader>
                                <strong>4/6</strong>Please fill out your guardian details
                            </MiddleSectionHeader>
                            <GuardianFormSection 
                                guardianInfo={guardianInfo}
                                consume={(e: GuardianInfo) => setGuardianInfo(e)}
                                checkForCompletion={(b: boolean) => setGuardianFormComplete(b)}
                                guardianInfoValidator={guardianInfoValidator}
                            />
                        </div>
                    </MiddleSection>
                    <BottomSection>
                        <i>Note: All fields are compulsory unless marked optional.</i>
                        <br />
                        <br />
                        <PreviousButton text="Previous" consume={() => setFormStage(FormStage.SCHOOL)}  />
                        <NextButton text="Next" consume={() => {handleGuardianButton()}} />
                    </BottomSection>
                </RightOuterLayer>
            </div>
        )
    }

    const confirmationFormStage = () => {
        return (
                <div>
                    <RightOuterLayer progress={84}>
                        <MiddleSection>
                            <div>
                                <MiddleSectionHeader>
                                    <strong>5/6</strong>Please confirm your details
                                </MiddleSectionHeader>
                                <ConfirmationSection 
                                    confirmationInfo={confirmationInfo}
                                    guardianInfo={guardianInfo}
                                    studentInfo={studentInfo}
                                    consume={(c: ConfirmationInfo) => setConfirmationInfo(c)}
                                    consumeGuardianInfo={(g: GuardianInfo) => setGuardianInfo(g)}
                                    consumeStudentInfo={(s: StudentInfo) => setStudentInfo(s)}
                                    checkForCompletion={(b: boolean) => setConfirmationFormComplete(b)}
                                    bookingType="NO-TEST"
                                />
                            </div>
                        </MiddleSection>
                        <BottomSection>
                            <i>Note: All fields are compulsory unless marked optional.</i>
                            <br />
                            <br />
                        <PreviousButton text="Previous" consume={() => setFormStage(FormStage.GUARDIAN)}  />
                        {confirmationFormComplete ? 
                            (<NextButton text="Next" consume={() => setFormStage(FormStage.PAYMENT)} />) : 
                            (<NextButton text="Next" consume={() => {
                                Swal.fire({
                                    title: "Please confirm your details",
                                    text: "Please ensure all confirmation boxes are selected and the contact details are valid and finalised",
                                    icon: "error",
                                    confirmButtonText: "Ok"
                                })
                            }} />
                        )}
                        
                        </BottomSection>
                    </RightOuterLayer>
                </div>
        )
    }

    const processStudentDetails = async () => {
        Swal.fire({
            title: 'Your Details Are Being Processed...',
            text: 'Please be patient, this may take up to 1 minute',
            allowOutsideClick: false, // Prevents user from closing the SWAL by clicking outside
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading(); // Shows the loading animation
            },
        });
        
        try {
            const response = await entryTestClient.createStudentRecordWithoutEntranceTest(
                studentInfo,
                schoolInfo,
                guardianInfo,
                confirmationInfo
            )

            Swal.close();

            if (response.emailSent === false ){
                Swal.fire({
                    title: "Confirmation Email Not Sent",
                    text: "Could not send Entrance Test Booking Email, please change your email and try again",
                    icon: "error",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    confirmButtonText: "Ok"
                })
            } else if (response.responseProcessed === false) {
                Swal.fire({
                    title: "Details Not Processed",
                    text: "Could not process your details, please make sure they are correct and try again",
                    icon: "error",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    confirmButtonText: "Ok"
                })
            } else {
                Swal.fire({
                    title: "Booking made successfully",
                    icon: "success",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    confirmButtonText: "Ok"
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.href = 'https://www.drdueducation.com.au';
                    }
                })
            }

        } catch (err) {
            // Need to do the await create student record
            console.log(err);
            Swal.fire({
                title: "Book was not successful, please try again.",
                text: `${err}`,
                icon: "error",
                confirmButtonText: "Ok"
            })
        }
    }

    const paymentFormStage = () => {
        return (
            <div>
                <RightOuterLayer progress={100}>
                    <MiddleSection>
                        <div>
                            <MiddleSectionHeader>
                                <strong>6/6</strong>Your booking is complete. 
                            </MiddleSectionHeader>
                        </div>
                        No upfront payment is required. Please click on the Finish button and we will be in touch with you shortly.
                    </MiddleSection>
                    <BottomSection>
                    <PreviousButton text="Previous" consume={() => setFormStage(FormStage.CONFIRMATION)}  />
                    <NextButton text="Finish" consume={() => {processStudentDetails()}} />
                    </BottomSection>
                </RightOuterLayer>
            </div>
        )
    }

    const getCurrentFormStage = () => {
        switch (formStage) {
            case FormStage.STUDENT:
                return (
                   <>{studentFormStage()}</>
                )
            
            case FormStage.SCHOOL:
                return (
                   <>{schoolFormStage()}</>
                )

            case FormStage.GUARDIAN:
                return (
                   <>{guardianFormStage()}</>
                )

            case FormStage.CONFIRMATION:
                return (
                   <>{confirmationFormStage()}</>
                )
                
            case FormStage.PAYMENT:
                return (
                   <>{paymentFormStage()}</>
                )
        }
    }

    return (
        <>
            {getCurrentFormStage()}
        </>
    )
}