import { ReactNode } from "react"

export const RightOuterLayer = (props: {progress: number, children: ReactNode}) => {
    return (
        <div id="top-wizard">
            <div
                id="progressbar"
                className="ui-progressbar ui-widget ui-widget-content ui-corner-all"
                role="progressbar"
                aria-valuemin={0}
                aria-valuemax={100}
            >
                <div
                    className="ui-progressbar-value ui-widget-header ui-corner-left"
                    style={{ width: `${props.progress}%` }}
                />
            </div>
            {/* /top-wizard */}
            <form
                id="wrapped"
                method="POST"
                action="phpmailer/review_phpmailer_template_smtp.php"
                className="wizard-form"

            >
                <input id="website" name="website" type="text" defaultValue="" />
                {props.children}

                {/* Leave for security protection, read docs for details */}
                
            </form>
        </div>
    )
}

export const MiddleSection = (props: any) => {
    return (
            <div id="middle-wizard" className="wizard-branch wizard-wrapper" tabIndex={0}>
                <div className="step wizard-step current" style={{}}>
                    {props.children}
                </div>
            </div>
    )
}


export const MiddleSectionHeader = (props: any) => {
    return (
            <h3 className="main_question wizard-header">
                {props.children}
            </h3>
    )
}

export const BottomSection = (props: any) => {
    return (
        <div id="bottom-wizard">
            {props.children}
        </div>
    )
}