export const PreviousButton = ({
    text,
    consume
}: {
    text: string;
    consume: () => void;
}) => {
    return (
        <button type="button" name="backward" className="backward" onClick={() => {
            {consume()}
        }}>
            {text}
        </button>
    )
}


export const NextButton = ({
    text,
    consume
}: {
    text: string;
    consume: () => void;
}) => {
    return (
        <button type="button" name="forward" className="forward" onClick={() => {
            {consume()}
        }}>
            {text}
        </button>
    )
}
