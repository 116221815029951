abstract class HttpClient {

    protected abstract getServicePath(): string;

    protected abstract getServiceURL(): string;

    protected buildOptions(httpMethod: string, customHeaders?: { [key: string]: string | undefined }, multiPart: boolean = false, noJwt: boolean = false): RequestInit {
        let options: any = { method: httpMethod }
        if (!multiPart) {
            options.headers = { "Content-Type": "application/json" }
        }
        if (customHeaders) {
            options.headers = { ...options.headers, ...customHeaders };
        }

        // We are using publically available endpoints so no need for auth

        return options;
    }

    protected async validateResponse(resp: Response): Promise<void> {
        if (resp.status === 200) {
            return;
        }
        const contentType = resp.headers.get("Content-Type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
            const data = await resp.json();
            let { errorMessage, correlationId } = data;
            if (errorMessage) {
                // Remove exception prefix
                errorMessage = errorMessage.split(" ").slice(1).join(" ");
            }
            throw new Error(`${errorMessage}`, { cause: `${correlationId}` });
        }
        throw new Error(`Server error: Status ${resp.status} ${await resp.text()}`);
    }

    async get<T>(
        postServicePath: string,
        headers?: {[key: string]: string | undefined},
        expectFileResponse: boolean = false,
        ): Promise<T> {
        return this.call<T>('GET', postServicePath, undefined, headers, false, expectFileResponse);
    }

    async post<T>(
        postServicePath: string, 
        data: any, 
        headers?: {[key: string]: string | undefined}, 
        multiPart: boolean = false, 
        expectFileResponse: boolean = false,
        noJwt: boolean = false,
    ): Promise<T> {
        return this.call<T>('POST', postServicePath, data, headers, multiPart, expectFileResponse, noJwt);
    }

    async put<T>(postServicePath: string, data: any, headers?: {[key: string]: string | undefined}): Promise<T> {
        return this.call<T>('PUT', postServicePath, data, headers);
    }

    async putNoRespBody(postServicePath: string, data: any, headers?: {[key: string]: string | undefined}): Promise<void> {
        return this.callNoRespBody('PUT', postServicePath, data, headers);
    }

    async delete<T>(postServicePath: string, headers?: {[key: string]: string | undefined}): Promise<T> {
        return this.call<T>('DELETE', postServicePath, undefined, headers);
    }

    async deleteNoRespBody(postServicePath: string, headers?: {[key: string]: string | undefined}): Promise<void> {
        return this.callNoRespBody('DELETE', postServicePath, undefined, headers);
    }

    async call<T>(
        method: string,
        postServicePath: string, 
        data?: any, 
        headers?: {[key: string]: string | undefined},
        multiPart: boolean = false,
        expectFileResponse: boolean = false,
        noJwt: boolean = false,
    ): Promise<T> {
        const url = `${this.getServiceURL()}${postServicePath}`;
        const fetchOptions = this.buildOptions(method, headers, multiPart, noJwt);
        if (data) {
            fetchOptions.body = multiPart ? data : JSON.stringify(data);
        }
        const resp = await fetch(url, fetchOptions);
        await this.validateResponse(resp);
        if (expectFileResponse) {
            // @ts-ignore
            return await resp.blob();
        }
        return (await resp.json()) as T;
    }

    async callNoRespBody(
        method: string,
        postServicePath: string, 
        data?: any, 
        headers?: {[key: string]: string | undefined},
        multiPart: boolean = false,
        expectFileResponse: boolean = false,
        noJwt: boolean = false,
    ): Promise<void> {
        const url = `${this.getServiceURL()}${postServicePath}`;
        const fetchOptions = this.buildOptions(method, headers, multiPart, noJwt);
        if (data) {
            fetchOptions.body = multiPart ? data : JSON.stringify(data);
        }
        const resp = await fetch(url, fetchOptions);
        await this.validateResponse(resp);
    }
}

export default HttpClient;
