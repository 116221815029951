import { PaymentInfo, PaymentInfoValidator } from "../../models/PaymentInfo";
import React from "react";
import { InputCVCBox, InputCardNumberBox, InputExpiryDateBox, InputTextBox, InputNameBox } from "../FormComponents/InputTextBox";
import { useCreditCardValidator, images } from "react-creditcard-validator";
import { error } from "console";

export const PaymentSection = ( props:{
    paymentInfo: PaymentInfo;
    consume: (val: PaymentInfo) => void;
    consumePaymentTouched: (val: PaymentInfoValidator) => void
    checkForCompletion: (val: boolean) => void;
    paymentInfoValidator: PaymentInfoValidator;
    paymentDetailsTouched: PaymentInfoValidator;
}) => {

    const [paymentInfo, setPaymentInfo] = React.useState<PaymentInfo>(props.paymentInfo)
    const [paymentDetailsTouched, setPaymentDetailsTouched] = React.useState<PaymentInfoValidator>(props.paymentDetailsTouched)

    // If Front Desk wants more shenanigan changes in the payment section we will used touchedInputs
    const {
        getCardNumberProps,
        getExpiryDateProps,
        getCVCProps,
        getCardImageProps,
        meta: { erroredInputs, touchedInputs }
      } = useCreditCardValidator();

    const paymentSectionComplete = () => {
        if (erroredInputs.cardNumber !== undefined ||
            erroredInputs.cvc !== undefined ||
            erroredInputs.expiryDate !== undefined ||
            paymentInfo.fullName === "" ||
            paymentInfo.CVC === "" ||
            paymentInfo.cardNumber === "" ||
            paymentInfo.expiryDate === ""
            ) {
            return false;
        }
        return true;
    }

    React.useEffect(
        () => {
            props.consume(paymentInfo)
            const formComplete = paymentSectionComplete()
            props.checkForCompletion(formComplete)
            props.consumePaymentTouched(paymentDetailsTouched)
        }
    , [paymentInfo])

    return (
        <>
            Card Owner Name:
            <div className="form-group">
                <input
                    type="string"
                    className={`form-control required ${((paymentInfo.fullName === "" || props.paymentInfoValidator.fullName) && props.paymentDetailsTouched.fullName) ? "is-invalid" : ""}`}
                    pattern="[a-z A-Z-]+"
                    placeholder="Full Name"
                    required
                    value={props.paymentInfo.fullName}
                    onChange={e => {
                        setPaymentInfo(prevState => ({...prevState, fullName: e.target.value}))
                        setPaymentDetailsTouched(prevState => ({...prevState, fullName: true}))
                    }}
                />
                {((paymentInfo.fullName === "" || props.paymentInfoValidator.fullName) && props.paymentDetailsTouched.fullName) && <div className="invalid-feedback">
                    Please enter the card owner name
                </div> }
            </div>
            Card Number:
            <div className="form-group">
                <input
                    {...getCardNumberProps({
                        onChange: e => {

                        }
                    })}
                    className={`form-control required ${(((!!erroredInputs.cardNumber || props.paymentInfo.cardNumber === "" || props.paymentInfoValidator.cardNumber)  && props.paymentDetailsTouched.cardNumber) )? "is-invalid" : ""}`}
                    placeholder="Card Number"
                    value={paymentInfo.cardNumber}
                    onChange={(e: any) => { 
                        const value = e.target.value.split(' ').join('');
                        if (value === '' || /^[0-9]+$/.test(value)) {
                            setPaymentInfo(prevState => ({...prevState, cardNumber : value}))
                            setPaymentDetailsTouched(prevState => ({...prevState, cardNumber: true}))
                        }
                    }}
                />
                {(((!!erroredInputs.cardNumber|| props.paymentInfo.cardNumber === "" || props.paymentInfoValidator.cardNumber)  && props.paymentDetailsTouched.cardNumber)) && <div className="invalid-feedback">
                    {erroredInputs.cardNumber ?? "Please enter a valid card number"}
                </div> }
            </div>
            Card Expiry Date:
            <div className="form-group">
                    <input
                        {...getExpiryDateProps({
                            onChange: e => {
                                const cardExpiry = e.target.value.split(' ').join('')
                                setPaymentInfo(prevState => ({...prevState, expiryDate : cardExpiry}))
                                setPaymentDetailsTouched(prevState => ({...prevState, expiryDate: true}))
                            }
                        })}
                        value={paymentInfo.expiryDate}
                        className={`form-control required ${((!!erroredInputs.expiryDate || props.paymentInfo.expiryDate === "" || props.paymentInfo.expiryDate === "\/" || props.paymentInfoValidator.expiryDate) && props.paymentDetailsTouched.expiryDate)  ? "is-invalid" : ""}`}
                    />
                
                {((!!erroredInputs.expiryDate || props.paymentInfo.expiryDate === "" || props.paymentInfo.expiryDate === "\/" || props.paymentInfoValidator.expiryDate) && props.paymentDetailsTouched.expiryDate)  && (
                    <div className="invalid-feedback">
                        {erroredInputs.expiryDate ?? "Please enter a valid expiry date"}
                    </div>
                )}
            </div>
            Card CVC:
            <div className="form-group">
            <input
                {...getCVCProps({
                    onChange: e => {
                        const cardCVC = e.target.value
                        setPaymentInfo(prevState => ({...prevState, CVC : cardCVC}))
                        setPaymentDetailsTouched(prevState => ({...prevState, CVC: true}))
                    }
                })}
                className={`form-control required ${((!!erroredInputs.cvc || paymentInfo.CVC === "" || props.paymentInfoValidator.CVC) && props.paymentDetailsTouched.CVC)  ? 'is-invalid' : ''}`}
                placeholder="Card CVC"
                value={paymentInfo.CVC}
            />
            {((!!erroredInputs.cvc || paymentInfo.CVC === "" || props.paymentInfoValidator.CVC) && props.paymentDetailsTouched.CVC)   && <div className="invalid-feedback">
                {erroredInputs.cvc ?? "Please enter a valid CVC"}
            </div> }
        </div>
        </>
    )
}