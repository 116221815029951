import HttpClient from "./HttpClient";

abstract class EMSXClient extends HttpClient {

    protected abstract getServicePath(): string;

    protected getServiceURL(): string {
        return `${process.env.REACT_APP_DRDU_API_ENDPOINT_ROOT}${this.getServicePath()}`;
    }
}

export default EMSXClient;
