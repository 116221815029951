import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { PREBOOKING_FORM, routes } from './routes/routes';

const App: React.FC = () => (
  <>
    <BrowserRouter>
      <Routes>
        {routes.map((r, i) => {
          const Component = r.component;
          return <Route
            key={i}
            path={r.path}
            element={<Component />}
          />
        })}
        <Route
          path="*"
          element={<Navigate to={PREBOOKING_FORM + window.location.search} />}
        />
      </Routes>
    </BrowserRouter>
  </>
);

export default App;