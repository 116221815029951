import { subjectsRequiringTest, subjects, Select } from "../FormComponents/Select";
import { RightOuterLayer, MiddleSection, MiddleSectionHeader, BottomSection } from "../FormComponents/FormSections";
import React from "react";
import { useNavigate} from "react-router";
import { ENTRANCE_TEST_BOOKING_FORM, NO_ENTRANCE_TEST_BOOKING_FORM } from "../../routes/routes";
import { NextButton, PreviousButton } from "../FormComponents/Buttons";
import { EntranceTestRequired } from './EntranceTestRequiredBookingSystem';
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import entryTestClient from "../../client/EntryTestClient";

export const PreBookingSystem = (props: {internal: boolean;}) => {
    // Initial logic for this booking system
    // Student can select up to 4 subjects to get tutoring
    // It is required that subject one is selected
    // If any of the subjects are in the subjectsRequiringTest, the student will 
    // have to sit an entrance test and fill out the entrance test time in their form
    // Otherwise the student will not require to sit the entrance test

    const [show, setShow] = React.useState(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [staffUserName, setStaffUserName] = React.useState('')
    const [staffPassword, setStaffPassword] = React.useState('')
    
    const [subjectOne, setSubjectOne] = React.useState('Select Subject One')
    const [subjectTwo, setSubjectTwo] = React.useState('Select Subject Two (Optional)')
    const [subjectThree, setSubjectThree] = React.useState('Select Subject Three (Optional)')
    const [subjectFour, setSubjectFour] = React.useState('Select Subject Four (Optional)')

    const navigate = useNavigate()
    const [isPreBooking, setIsPreBooking] = React.useState(true)

    const entranceTestSubjects = new Set<string>(subjectsRequiringTest)

    const subjectOneSelected = () => {
        return subjectOne !== "Select Subject One"
    }

    const duplicateSubjects = () => {
        const values = new Set([
            subjectOne,
            subjectTwo,
            subjectThree,
            subjectFour
        ])
        return values.size !== 4;
    }

    const requiredToSitTest = () => {
        if (entranceTestSubjects.has(subjectOne)) {
            return true
        }
        if (entranceTestSubjects.has(subjectTwo)) {
            return true
        }
        if (entranceTestSubjects.has(subjectThree)) {
            return true
        }
        if (entranceTestSubjects.has(subjectFour)) {
            return true
        }
        return false
    }

    const goToNextForm = () => {
        return (
            <>
                {
                    requiredToSitTest() ? 
                    (
                        setIsPreBooking(false)
                    )
                    : 
                    (
                        (navigate(`${NO_ENTRANCE_TEST_BOOKING_FORM}?subjectOne=${subjectOne}&subjectTwo=${subjectTwo}&subjectThree=${subjectThree}&subjectFour=${subjectFour}&internal=${props.internal}`))
                    
                    )
                }
            </>
        )
    }
    
    const getButton = () => {
        if (!subjectOneSelected()) {
            return (
                <PreviousButton text="Subject One must be selected" consume={() => {}}/>
            )
        }
        if (duplicateSubjects()) {
            return (
                <PreviousButton text="Cannot select duplicate subjects" consume={() => {}}/>
            )
        }
        return (
            <NextButton text="Next" consume={() => goToNextForm()} />
        )
    }

    const SubjectSelection = () => {
        return (
            <>
                {
                    isPreBooking ?
                    (
                        <div>
                            <RightOuterLayer progress={14}>
                                <MiddleSection>
                                    <div>
                                        <MiddleSectionHeader>
                                            <strong>{requiredToSitTest() ? "1/7" : "1/6"}</strong>Please select the subjects you would like to study with us
                                        </MiddleSectionHeader>
                                        <Select
                                            consume={(subject: any) => {
                                                setSubjectOne(subject)
                                                }
                                            }
                                            placeholder = "Select Subject One"
                                            values={subjects}
                                        />
                                        {(subjectOne !== "Select Subject One" && subjectOne !== "") && 
                                            <Select
                                                consume={
                                                    (subject: any) => {
                                                        if (subject === "Select Subject Two (Optional)" || subject === "") {
                                                            setSubjectTwo("Select Subject Two (Optional)")
                                                            setSubjectThree("Select Subject Three (Optional)")
                                                            setSubjectFour("Select Subject Four (Optional)")
                                                        } else {
                                                            setSubjectTwo(subject)
                                                        }
                                                    }
                                                }
                                                placeholder = "Select Subject Two (Optional)"
                                                values={subjects}
                                            />
                                        }
                                        {(subjectTwo !== "Select Subject Two (Optional)" && subjectTwo !== "") &&
                                            <Select
                                                consume={
                                                    (subject: any) => {
                                                        if (subject === "Select Subject Three (Optional)" || subject === "") {
                                                            setSubjectThree("Select Subject Three (Optional)")
                                                            setSubjectFour("Select Subject Four (Optional)")
                                                        } else {
                                                            setSubjectThree(subject)
                                                        }
                                                    }
                                                }
                                                placeholder = "Select Subject Three (Optional)"
                                                values={subjects}
                                            />
                                        }
                                        {(subjectThree !== "Select Subject Three (Optional)" && subjectThree !== "") &&
                                            <Select
                                                consume={
                                                    (subject: any) => {
                                                        if (subject === "Select Subject Four (Optional)" || subject === "") {
                                                            setSubjectFour("")
                                                        } else {
                                                            setSubjectFour(subject)
                                                        }
                                                    }
                                                }
                                                placeholder = "Select Subject Four (Optional)"
                                                values={subjects}
                                            />
                                        }
                                    </div>
                                </MiddleSection>
                                <BottomSection>
                                    <i>Note: Some courses will require an entrance test.</i>
                                    <br />
                                    <br />
                                    {getButton()}
                                </BottomSection>
                            </RightOuterLayer>
                        </div>
                    )
                    :
                    (
                        <EntranceTestRequired 
                            internal={props.internal}
                            subjectOne={subjectOne}
                            subjectTwo={subjectTwo}
                            subjectThree={subjectThree}
                            subjectFour={subjectFour}
                        />
                    )
                }
            </>
        )
    }

    const checkForValidLogin = async() => {
        try {
            const internalLoginDto = await entryTestClient.checkForInternalUser({"username": staffUserName, password: staffPassword})
            if (internalLoginDto.validEmsUser) {
                await Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Staff Credentials are Valid!',
                    showConfirmButton: true
                }).then(() => {
                    handleClose();
                });
            }  else {
                await Swal.fire({
                    icon: 'warning',
                    title: 'Invalid Staff Credentials!',
                    text: 'Staff Credentials are Invalid!',
                    showConfirmButton: true
                })
            }
        } catch (error) {
            await Swal.fire({
                icon: 'error',
                text: `${error}`,
                showConfirmButton: true
            })
            console.error('Fetch error:', error);
        }
    }
    
    const renderLoginForInternalUse = () => {
        return (
            <div style={{overflowX: 'auto'}}>
                {props.internal && 
                    <Modal 
                        size="lg"
                        show={show} 
                        onShow={handleShow}
                        keyboard={false}
                        backdrop={true}
                        >
                        <Modal.Header>
                            <Modal.Title className="w-100 text-center">Login Via Staff Credentials</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group className="mb-3">
                                <Form.Control type="text" placeholder="Staff Username" onChange={(e) => {setStaffUserName(e.target.value)}} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Control type="password" placeholder="Staff Password" onChange={(e) => {setStaffPassword(e.target.value)}} />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={() => {checkForValidLogin()}}>
                                Login
                            </Button>
                        </Modal.Footer>
                    </Modal>
                }
            </div>
        )
    }

    return (
        <>
            {SubjectSelection()}
            {renderLoginForInternalUse()}
        </>
    )
}