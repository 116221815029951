import { useState, useEffect } from "react";
import { InputEmailTextBox, InputPhoneNumberBox, InputTextBox, InputTextBoxWithValidation } from "../FormComponents/InputTextBox";
import { guardianOptions } from "../FormComponents/Select";
import { Select } from "../FormComponents/Select";
import { validatePhoneNumber, validateEmail, validateGenericName } from "../../utils/validatorUtils";
import { GuardianInfo, GuardianInfoValidator } from "../../models/GuardianInfo";
import { SelectCheckbox } from "../FormComponents/CheckBox";


export const GuardianFormSection = (props: {
    guardianInfo: GuardianInfo;
    consume: (val: GuardianInfo) => void;
    checkForCompletion: (val: boolean) => void;
    guardianInfoValidator: GuardianInfoValidator;
}) => {
    const [guardianInfo, setGuardianInfo] = useState(props.guardianInfo)
    const [validGuardianName, setValidGuardianName] = useState<boolean>(validateGenericName(props.guardianInfo.name))
    const [validGuardianFamilyName, setValidGuardianFamilyName] = useState<boolean>(validateGenericName(props.guardianInfo.familyName))
    const [validNumber, setValidNumber] = useState(validatePhoneNumber(props.guardianInfo.number));
    const [validEmail, setValidEmail] = useState(validateEmail(props.guardianInfo.email));
    
    const guardianStageComplete = () => {
        if (
            (guardianInfo.name === null || guardianInfo.name === '') || 
            (guardianInfo.familyName === null || guardianInfo.familyName === '') || 
            (guardianInfo.number === null || guardianInfo.number === '') || 
            (guardianInfo.email === null || guardianInfo.email === '') || 
            (guardianInfo.relationship === null || guardianInfo.relationship === '' || guardianInfo.relationship === 'Select Option')||
            (guardianInfo.gender === null || guardianInfo.gender === '')
        ) {
            return false;
        }

        if (!validNumber || !validEmail || !validGuardianFamilyName || !validGuardianName) {
            return false;
        }
        if (guardianInfo.relationship === 'Other' && guardianInfo.relationshipDetails === '') {return false;}
        return true;
    }

    useEffect(() => {
        if (guardianInfo.relationship === 'Other') {
            setGuardianInfo(prevState => ({...prevState, relationshipDetails: ''}))
        }
    }, [guardianInfo.relationship])


    useEffect(() => {
        props.consume(guardianInfo)
        const formComplete = guardianStageComplete()
        props.checkForCompletion(formComplete)

    }, [guardianInfo])

    return (
        <>
            Guardian gender:
            <SelectCheckbox 
                optionOne="Male" 
                optionTwo="Female"  
                value={guardianInfo.gender}
                consume={(n: any) => setGuardianInfo(prevState => ({...prevState, gender: n}))}
                errorMessage={props.guardianInfoValidator.gender}
                />
            <InputTextBoxWithValidation
                placeholder="Guardian Given Name"
                value={guardianInfo.name}
                consume={(n) => setGuardianInfo(prevState => ({...prevState, name: n}))}
                errorMessage={props.guardianInfoValidator.name}
                checkIfValid={(b: boolean) => setValidGuardianName(b)}
                englishOnly={true}
                />
            <InputTextBoxWithValidation 
                placeholder="Guardian Family Name"
                value={guardianInfo.familyName}
                consume={(n) => setGuardianInfo(prevState => ({...prevState, familyName: n}))}
                errorMessage={props.guardianInfoValidator.familyName}
                checkIfValid={(b: boolean) => setValidGuardianFamilyName(b)}
                englishOnly={true}
                />
            <InputPhoneNumberBox 
                placeholder="Guardian Phone Number"
                value={guardianInfo.number}
                consume={(n) => setValidNumber(n)}
                updateValue={(n) => setGuardianInfo(prevState => ({...prevState, number: n}))}
                errorMessage={props.guardianInfoValidator.number}
                />
            <InputEmailTextBox 
                placeholder="Guardian Email Address"
                value={guardianInfo.email} 
                consume={(n) => setValidEmail(n)}
                updateValue={(n) => setGuardianInfo(prevState => ({...prevState, email: n}))}
                errorMessage={props.guardianInfoValidator.email}
            />
            Relationship to Student 
            <Select
                consume={(n) => setGuardianInfo(prevState => ({...prevState, relationship: n}))}
                placeholder="Select Option"
                values={guardianOptions}
                initialValue={guardianInfo.relationship}
                errorMessage={props.guardianInfoValidator.relationship}
            />
            {
                guardianInfo.relationship === 'Other' 
                && 
                <InputTextBox 
                    placeholder="Please specify relationship"
                    value={guardianInfo.relationshipDetails}
                    consume={(n) => setGuardianInfo(prevState => ({...prevState, relationshipDetails: n}))}
                    errorMessage={props.guardianInfoValidator.relationshipDetails}
                    englishOnly={true}
                />
            }
        </>
    )
}