import EMSXClient from "./EMSXClient";
import queryString from 'query-string';
import { DateTime } from 'luxon';
import { ConfirmationInfo } from '../models/ConfirmationInfo';
import { GuardianInfo } from "../models/GuardianInfo";
import { SchoolInfo } from "../models/SchoolInfo";
import { StudentInfo } from "../models/StudentInfo";
import { PaymentInfo } from "../models/PaymentInfo";
import { NoEntranceTestBookingInfo } from "../models/NoEntranceTestBookingInfo";
import { EntranceTestBookingInfo } from "../models/EntranceTestBookingInfo";
import { CreateStudentWithEntranceTest } from '../models/CreateStudentWithEntranceTest';
import { CreateStudentWithNoEntranceTest } from '../models/CreateStudentWithNoEntranceTest';
import { createStudentRecordForEntranceTestFromInterface, createStudentRecordForNoEntranceTestFromInterface } from "../utils/combineInterfaceUtils";
import entranceTestBookingResponse from "../models/entranceTestBookingResponse";
import noEntranceTestBookingResponse from "../models/noEntranceTestBookingResponse";
import { TimeslotDto } from "../models/TimeslotDto";
import { VoucherResponseDto } from "../models/VoucherResponseDto";
import { VoucherInfo } from '../models/VoucherInfo';
import { InternalLoginDetails } from '../models/InternalLoginDetails';
import { InternalLoginDto } from "../models/InternalLoginDto";
export class EntryTestClient extends EMSXClient {
    protected getServicePath() : string {
        return ""
    }

    async getCapacityByDateTimeCentre(time: string, date: string, centreName: string) {
        const timeslot = DateTime.fromFormat(`${date} ${time}`, "yyyy-MM-dd h:mma").toFormat("yyyy-MM-dd HH:mm:ss")
        const queryParams = {
            centreName,
            timeslot
        };
        const queryStringifyParams = queryString.stringify(queryParams);
        const requestUrl = `/efficient-software-search-capacity?${queryStringifyParams}`
        return await super.get<any>(`${requestUrl}`)
    }

    async getSuburbs() {
        const requestUrl = `/efficient-software-suburb-search`
        return await super.get<any>(`${requestUrl}`)
    }

    async getSchools() {
        const requestUrl = `/efficient-software-school-search`
        return await super.get<any>(`${requestUrl}`)
    }
    async createStudentRecordWithEntranceTest(
        studentInfo: StudentInfo,
        schoolInfo: SchoolInfo,
        bookingInfo: EntranceTestBookingInfo,
        guardianInfo: GuardianInfo,
        confirmationInfo: ConfirmationInfo,
        paymentInfo: PaymentInfo,
        voucherInfo: VoucherInfo,
        internal: boolean
    ) : Promise<entranceTestBookingResponse> {
        
        const record = createStudentRecordForEntranceTestFromInterface(
            studentInfo,
            schoolInfo,
            bookingInfo,
            guardianInfo,
            confirmationInfo,
            paymentInfo,
            voucherInfo
        )
        if (internal) {
            return await super.post<entranceTestBookingResponse>("/efficient-software-create-student-record-with-test-internal", record)
        } else {
            return await super.post<entranceTestBookingResponse>("/efficient-software-create-student-record-with-test", record)
        }
    }

    async createStudentRecordWithoutEntranceTest(
        studentInfo: StudentInfo,
        schoolInfo: SchoolInfo,
        guardianInfo: GuardianInfo,
        confirmationInfo: ConfirmationInfo
    ) {
        const record = createStudentRecordForNoEntranceTestFromInterface(
            studentInfo,
            schoolInfo,
            guardianInfo,
            confirmationInfo
        )
        return await super.post<noEntranceTestBookingResponse>("/efficient-software-create-student-record-without-test", record)
    }

    async getTimeslots() {
        const requestUrl = `/efficient-software-timeslot`
        return await super.get<TimeslotDto[]>(`${requestUrl}`)
    }

    async checkForVoucherCode(voucherCode: string) {
        const queryStringifyParams = queryString.stringify({voucherCode})
        return await super.get<VoucherResponseDto>(`/efficient-software-voucher-check?${queryStringifyParams}`)
    }

    async checkForInternalUser(internalLoginDetails: InternalLoginDetails) {
        return await super.post<InternalLoginDto>("/efficient-internal-login", internalLoginDetails)
    }
}

const entryTestClient = new EntryTestClient()
export default entryTestClient