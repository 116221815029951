import React from 'react';
import { VoucherInfo } from '../../models/VoucherInfo';
import { InputTextBox } from "../FormComponents/InputTextBox"
import entryTestClient from '../../client/EntryTestClient';
import { NextButton, PreviousButton } from '../FormComponents/Buttons';

export const VoucherSection = (props: {
    voucherInfo: VoucherInfo;
    consume: (val: VoucherInfo) => void;
    checkValidVoucher: (val: boolean) => void;
}) => {


    const [voucherInfo, setVoucherInfo] = React.useState<VoucherInfo>(props.voucherInfo)
    const [voucherErrorMessage, setVoucherErrorMessage] = React.useState('');
    const [validMessage, setValidMessage] = React.useState('');

    
    // Use Effect for handling side effects
    React.useEffect(() => {
        props.consume(voucherInfo);
    }, [voucherInfo]); 

    const processVoucherCode = async() => {
        props.consume(voucherInfo)
        var voucherCodeResponse = await entryTestClient.checkForVoucherCode(voucherInfo.voucherCode)
        switch (voucherCodeResponse.entranceTestVoucherStatus) {
            case "VALID":
                setVoucherErrorMessage("")
                setValidMessage("Voucher Code is Valid")
                props.checkValidVoucher(true)
                break;
                
            case "INVALID":
                setVoucherErrorMessage("Voucher Code is Invalid")
                setValidMessage("")
                props.checkValidVoucher(false)
                break;

            case "USED":
                setVoucherErrorMessage("Voucher Code has been used")
                setValidMessage("")
                props.checkValidVoucher(false)
                break;
                      
            case "EXPIRED":
                setVoucherErrorMessage("Voucher Code is expired")
                setValidMessage("")
                props.checkValidVoucher(false)
                break;

            case "QUARANTINED":
                setVoucherErrorMessage("Voucher Code has been used")
                setValidMessage("")
                props.checkValidVoucher(false)
                break

            case "NOTENTRANCETEST":
                setVoucherErrorMessage("This is not an entrance test voucher")
                setValidMessage("")
                props.checkValidVoucher(false)
                break;

            default:
                break;
        }
    }

    const clearVoucherInfo = () => {
        setValidMessage("");
        setVoucherErrorMessage("");
        props.checkValidVoucher(false);
        setVoucherInfo(prevState => ({
            ...prevState,
            voucherCode: ""
        }));
    }


    return (
        <>
            <b>Card Details not used if your voucher has been validated: </b>
            <br/>
            Please enter your voucher code:
            <InputTextBox 
                placeholder="Voucher Code"
                value={voucherInfo.voucherCode}
                consume={(c) => setVoucherInfo(prevState => ({...prevState, voucherCode: c}))}
                errorMessage={voucherErrorMessage}
                validMessage={validMessage}
                isDisabled={validMessage === "Voucher Code is Valid"}
                englishOnly={true}
            />
            <PreviousButton 
                text="Clear"
                consume = {() => {clearVoucherInfo()}}
            />
            <NextButton 
                text="Validate"
                consume = {() => {
                    if (voucherInfo.voucherCode.length !== 8) {
                        props.consume(voucherInfo)
                        props.checkValidVoucher(false)
                        setValidMessage("")
                        setVoucherErrorMessage("Voucher Code must have 8 characters")
                    } else {
                        processVoucherCode()
                    }
                }}
            />
           <br />
           <br />
        </>
    )
}


