import React from "react";
import { PLVCL } from "../components/Layout/PLVCL";
import { PreBookingSystem } from '../components/BookingSystem/PreBookingSystem';
import { TextContext } from "../components/ContextManagers/TextContext";
export class PreBookingPage extends React.Component {
    state = {
        text: "Thank you for your interest in attending Dr. Du Education. <br /> Please select your subject(s) to commence your enrolment"
    }

    setText = (newText: string) => {
        this.setState({text: newText})
    }
    render = () => {
        return (
            <TextContext.Provider value={{ text: this.state.text, setText: this.setText }}>
                <PLVCL 
                    heading="Dr. Du Education Enrolment" 
                    text={this.state.text}
                    >
                    <PreBookingSystem internal={false} />
                </PLVCL>
            </TextContext.Provider>
        );
    }
}
