import { useState, useRef, useEffect } from "react";
import React from "react";

// This will be an API call later
export const subjects = [
    "Y9 English",
    "Y9 Mathematics",
    "Y10 English",
    "Y10 Mathematics",
    "Y11 Mathematics Extn. 1",
    "Y11 Advanced Mathematics",
    "Y11 Chemistry",
    "Y11 Physics",
    "Y11 English",
    "Y12 Mathematics Extn. 2",
    "Y12 Mathematics Extn. 1",
    "Y12 Advanced Mathematics",
    "Y12 Chemistry",
    "Y12 Physics",
    "Y12 English"
]

// This will be an API call later
export const subjectsRequiringTest = [
    "Y9 Mathematics",
    "Y10 Mathematics",
    "Y11 Mathematics Extn. 1",
    "Y11 Advanced Mathematics",
    "Y12 Mathematics Extn. 2",
    "Y12 Mathematics Extn. 1",
    "Y12 Advanced Mathematics",
]

export const studentYears = [
    "Year 7",
    "Year 8",
    "Year 9",
    "Year 10",
    "Year 11",
    "Year 12",
]

export const guardianOptions = [
    "Mother",
    "Father",
    "Other"
]

export const sampleTestTimes = [
    "10:30am",
    "12:00pm",
    "3:30pm",
    "5:00pm"
]

export const sms_type = [
    "Mother",
    "Father",
    "Other"
]

export const Select = ({
    placeholder,
    consume, 
    initialValue,
    values,
    children,
    disabledValues,
    errorMessage
}: {
    placeholder: string;
    consume: (value: string) => void;
    initialValue?: string;
    values: Array<any>;
    children?: React.ReactNode;
    disabledValues?: Array<string>;
    errorMessage?: string;
}) => {
    const [value, setValue] = useState<string|undefined>(initialValue);
    const [selection, setSelection] = useState(false)

    const selectRef = useRef<HTMLDivElement>(null); // Ref for the select box 

    // Handler for click outside the select box
    const handleClickOutside = (event: any) => {
        if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
            setSelection(false)
        }
    }
    
    useEffect(() => {
        // Attach the handler to the document
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Clean up the event listener
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const errorStyle = {
        border: '1px solid #dc3545', // Adjust the thickness as needed
        borderRadius: '4px', // Match the border-radius of other inputs if they have one
        // Add any other styles to match the design of other input elements
    };

    // Apply error styles if errorMessage is present
    const selectStyle = errorMessage ? errorStyle : {};

    const errorMessageStyle = {
        color: '#dc3545',
        fontSize: '0.875em', // Adjust the font size as needed to match your design
        marginTop: '4px', // Adjust spacing to align with your form layout
        // Add other styles to match the error message with the rest of the form
    };

    return (
        <div className="form-group" ref={selectRef}>
            <div className={`styled-select clearfix ${errorMessage ? "is-invalid" : ""}`}>
                <div className={`nice-select wide ${selection ? "open" : ""}`} 
                    tabIndex={0} 
                    onClick={() => {setSelection(!selection)}}
                    style={selectStyle}
                >
                    <span className="current" >
                        {value && value.length ? value : placeholder}
                    </span>
                    <ul className={`list ${selection ? "open" : ""}`}>
                        <li key="value Selection"
                            data-value={`${placeholder}`} 
                            className={`option ${value === `${placeholder}` ? 'selected focus' : ''}`}
                            onClick={()=>{
                                consume("")
                                setValue("")
                            }}
                            >
                            {placeholder}
                        </li>
                        {values.map((val, idx) => {
                        return <li key={`${val} ${idx}`}
                                data-value={`${val}`} 
                                className={`option ${value === val ? 'selected focus' : ''} ${disabledValues && disabledValues.includes(val) ? 'disabled' : ''}`}
                                onClick={()=>{
                                    if (disabledValues && disabledValues.includes(val)) {
                                        return;
                                    }
                                    consume(val)
                                    setValue(val)
                                }}
                                >{val}</li>
                        })}
                    </ul>
                </div>   
            {errorMessage && (
                <div style={errorMessageStyle}>
                    {errorMessage}
                </div>
            )}      
            </div>
        </div>
    )
}


export const SelectWithSearch = ({
    placeholder,
    consume, 
    initialValue,
    values,
    children,
    disabledValues,
    errorMessage
}: {
    placeholder: string;
    consume: (value: string) => void;
    initialValue?: string;
    values: Array<any>;
    children?: React.ReactNode
    disabledValues?: Array<string>
    errorMessage?: string
}) => {
    const [value, setValue] = useState<string | undefined>(initialValue);
    const [selection, setSelection] = useState(false);
    const [searchTerm, setSearchTerm] = useState<string>("");

    const selectRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
                setSelection(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const filteredValues = values.filter(val => 
        val.toLowerCase().startsWith(searchTerm.toLowerCase())
    );

    const errorStyle = {
        border: '1px solid #dc3545', // Adjust the thickness as needed
        borderRadius: '4px', // Match the border-radius of other inputs if they have one
        // Add any other styles to match the design of other input elements
    };

    // Apply error styles if errorMessage is present
    const selectStyle = errorMessage ? errorStyle : {};

    const errorMessageStyle = {
        color: '#dc3545',
        fontSize: '0.875em', // Adjust the font size as needed to match your design
        marginTop: '4px', // Adjust spacing to align with your form layout
        // Add other styles to match the error message with the rest of the form
    };

    return (
        <div className="form-group" ref={selectRef}>
            <div className="styled-select clearfix">
                <div className={`nice-select wide ${selection ? "open" : ""}`} 
                    tabIndex={0} 
                    onClick={() => {setSelection(!selection)}}
                    style={selectStyle}
                >
                    <span className="current" >
                        {value && value.length ? value : placeholder}
                    </span>
                    <ul className={`list ${selection ? "open" : ""}`}>
                        <li key="search-box" className="option">
                            <input
                                type="text"
                                value={searchTerm}
                                placeholder="Search..."
                                onChange={e => setSearchTerm(e.target.value)}
                                onClick={e => e.stopPropagation()}
                                style={errorMessage ? errorStyle : {}}
                                className={`form-control required ${errorMessage ? "is-invalid" : ""}`}
                            />
                        </li>
                        {filteredValues.map((val, idx) => (
                            <li key={`${val} ${idx}`}
                                data-value={`${val}`} 
                                className={`option ${value === val ? 'selected focus' : ''} ${disabledValues && disabledValues.includes(val) ? 'disabled' : ''}`}
                                onClick={()=>{
                                    if (!(disabledValues && disabledValues.includes(val))) {
                                        consume(val);
                                        setValue(val);
                                        setSelection(false);
                                    }
                                }}
                                style={disabledValues && disabledValues.includes(val) ? { color: 'grey' } : {}}
                            >
                                {val}
                            </li>
                        ))}
                    </ul>
                </div>   
            </div>
            {errorMessage && (
                <div style={errorMessageStyle}>
                    {errorMessage}
                </div>
            )}    
        </div>
    );
};