import React from "react";
import { useNavigate, useLocation } from "react-router";
import { PREBOOKING_FORM } from "../../routes/routes";
import { subjects as existingSubjects } from "../FormComponents/Select";

const subjects = new Set(existingSubjects)

export const PreBookingParameterValidation = (props: any) => {
    const navigate = useNavigate();
    const location = useLocation();
    React.useEffect(() => {
        const params = new URLSearchParams(location.search);
        const subjectOne = params.get("subjectOne") 
        const subjectTwo = params.get("subjectTwo")
        const subjectThree = params.get("subjectThree")
        const subjectFour = params.get("subjectFour")

        // Subject One has to be exist, in addition it has to be in the list of subjects currently shown
        // Subject Two/Three/Four has to be exist, in addition it has to be in the list of subjects currently shown or be an optional unselected value
        
        if (subjectOne === null || subjectOne === undefined || !subjects.has(subjectOne)) {
            navigate(PREBOOKING_FORM)
        }
        if (subjectTwo === null || subjectTwo === undefined || (!subjects.has(subjectTwo) && subjectTwo !== 'Select Subject Two (Optional)')) {
            navigate(PREBOOKING_FORM)
        }
        if (subjectThree === null || subjectThree === undefined || (!subjects.has(subjectThree) && subjectThree !== 'Select Subject Three (Optional)')) {
            navigate(PREBOOKING_FORM)
        }
        if (subjectFour === null || subjectFour === undefined || (!subjects.has(subjectFour) && subjectFour !== 'Select Subject Four (Optional)')) {
            navigate(PREBOOKING_FORM)
        }
    }, [])

    return (
        <>
            {props.children}    
        </>
    )
}