import { useState, useEffect } from "react"

export const CheckBox = (props: {text: string; consume: (val: any) => void}) => {
    const [isChecked, setIsChecked] = useState(false)

    useEffect(() => {
        props.consume(isChecked)
    }, [isChecked])

    return (
        <div className="form-group">
            <label className="container_check version_2">
                {props.text}
                <input
                type="checkbox"
                defaultValue="`${props.text}`"
                className="required"
                onChange={(e: any) => {setIsChecked(!isChecked)}}
                />
                <span className="checkmark" />
            </label>
        </div>
    )
}


export const SelectCheckbox = (props: {
    optionOne: string;
    optionTwo: string;
    value: any;
    consume: (val: any) => void;
    errorMessage?: string;
}) => {
    const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.consume(e.target.value);
    }

    const isError = props.errorMessage ? 'is-invalid' : '';

    return (
        <div className="form-group radio_input">
            <label className={`container_radio ${isError}`}>
                {props.optionOne}
                <input
                    type="radio"
                    name="location"
                    value={props.optionOne}
                    className={`form-control required ${isError}`}
                    checked={props.value === props.optionOne}
                    onChange={handleOptionChange}
                />
                <span className="checkmark" />
            </label>
            <label className={`container_radio ${isError}`}>
                {props.optionTwo}
                <input
                    type="radio"
                    name="location"
                    value={props.optionTwo}
                    className={`form-control required ${isError}`}
                    checked={props.value === props.optionTwo}
                    onChange={handleOptionChange}
                />
                <span className="checkmark" />
            </label>
            {props.errorMessage && (
                <div className="invalid-feedback">
                    {props.errorMessage}
                </div>
            )}
        </div>
    );
}