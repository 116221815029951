import React from "react";
import { InputBookingDate, InputPhoneNumberBox, InputTextBox } from "../FormComponents/InputTextBox";
import { SelectCheckbox } from "../FormComponents/CheckBox";
import { sms_type } from "../FormComponents/Select";
import { Select } from "../FormComponents/Select";
import entryTestClient from "../../client/EntryTestClient";
import { dayToNumber, time12HourTo24Hour, time24HourTo12Hour } from '../FormComponents/EntranceTestTimeSlotsUtils';
import { validateBookingDate, validatePhoneNumber } from "../../utils/validatorUtils";
import { EntranceTestBookingInfo, EntranceTestBookingInfoValidator } from "../../models/EntranceTestBookingInfo";
import { createBuilderStatusReporter } from "typescript";

export const EntranceTestBookingSection = (props: {
    bookingInfo: EntranceTestBookingInfo;
    consume: (val: EntranceTestBookingInfo) => void;
    checkForCompletion: (val: boolean) => void;
    bookingInfoValidator: EntranceTestBookingInfoValidator;
}) => {

    const [bookingInfo, setBookingInfo] = React.useState<EntranceTestBookingInfo>(props.bookingInfo)
    const [validBookingDate, setValidBookingDate] = React.useState(validateBookingDate(props.bookingInfo.date));
    const [timeSlots, setTimeSlots] = React.useState<any>([]);
    const [unavailableTimes, setUnavailableTimes] = React.useState<any>([])
    const [timeSlotMappings, setTimeSlotMappings] = React.useState<any>({})

    const [selectKey, setSelectKey] = React.useState(0)


    const bookingStageComplete = () => {
        if (
            (bookingInfo.location === null || bookingInfo.location === '') ||
            (bookingInfo.date === null) || (bookingInfo.date === '') ||
            (bookingInfo.testTime === null || bookingInfo.testTime === '' || bookingInfo.testTime ==='Select Option') ||
            (!validateBookingDate(bookingInfo.date))
        ) {
            return false;
        }
        return true;
    }

    React.useEffect(
        () => {
            const fetchData = async () => {
                const timeslotDtos = await entryTestClient.getTimeslots();
                // Convert DTOs from API to a more usable format:
                // {
                //     "Burwood": {
                //         0: [
                //             "1:00PM",
                //             "3:30PM",
                //             "5:00PM",
                //         ],
                //         1: [
                //             "10:30AM",
                //             "12:00PM",
                //             "3:30PM",
                //             "5:00PM"
                //         ],
                //         2: [
                // etc.
                const timeslotMappings = timeslotDtos.reduce((acc: any, timeslotDto: any) => {
                    const {centre, day, startTime} = timeslotDto;
                    const centreName = centre.name;
                    const dayNumber = dayToNumber[day];
                    if (!acc[centreName]) {
                        acc[centreName] = {};
                    }
                    if (!acc[centreName][dayNumber]) {
                        acc[centreName][dayNumber] = [];
                    }
                    acc[centreName][dayNumber].push(time24HourTo12Hour(startTime));
                    return acc;
                }, {});
                setTimeSlotMappings(timeslotMappings);
            }
            fetchData().catch((e) => console.log(e));
        }
    , []);
    
    React.useEffect(
        () => {
            props.consume(bookingInfo)
            const formComplete = bookingStageComplete()
            props.checkForCompletion(formComplete)
        }
    , [bookingInfo])

    
    React.useEffect(
        () => {
            const getCapacities = async (timeSlots: any[]) => {
                try {
                    const results = await Promise.all(timeSlots.map(async (t: any) => {
                        const time_capacity = await entryTestClient.getCapacityByDateTimeCentre(t, bookingInfo.date.toString(), bookingInfo.location)
                        return {"time": t, "capacity": time_capacity}
                    }))
                    return results;
                } catch (error) {
                    console.log('Error: ', error);
                }
            }

            const refreshCapacity = async () => {
                setBookingInfo(prevState => ({...prevState, testTime: "Select Option"}))
                if (bookingInfo.date !== '' && (bookingInfo.location === 'Burwood' || bookingInfo.location === 'Hurstville')) {
                    const date = new Date(bookingInfo.date)
                    const dayOfWeek = date.getDay()
                    let availableTimeSlots = timeSlotMappings[bookingInfo.location][dayOfWeek]
                    const currentDate = new Date();
                    currentDate.setHours(0, 0, 0, 0)
                    let dateAtMidnight = new Date(bookingInfo.date)
                    dateAtMidnight.setHours(0, 0, 0, 0)
                    if (dateAtMidnight.getTime() === currentDate.getTime()) {
                        const currentTime = new Date();
                        availableTimeSlots = availableTimeSlots.filter((slot: any) => {
                            const slotTime = new Date(date);
                            const [hours, minutes, _] = time12HourTo24Hour(slot).split(":");
                            slotTime.setHours(parseInt(hours), parseInt(minutes))
                            return slotTime > currentTime
                        })
                    }
                    
                    try {
                        const slots = await getCapacities(availableTimeSlots)
                        setTimeSlots(slots)
                        setUnavailableTimes(slots?.filter((t) => t.capacity < 1))
                        
                    } catch (error) {
                        console.log('Error: ', error)
                    }
                }
            }

            setSelectKey(prevKey => prevKey + 1)
            
            setTimeSlots([])
            setUnavailableTimes([])
            setBookingInfo(prevState => ({...prevState, testTime: "Select Option"}))
            if (validBookingDate) {
                refreshCapacity();
            } 
        }, [bookingInfo.date, bookingInfo.location])

    return (
        <>
            Please select where you would like to take the test
            <SelectCheckbox 
                optionOne="Burwood" 
                optionTwo="Hurstville" 
                value={bookingInfo.location}
                consume={(n) => setBookingInfo(prevState => ({...prevState, location: n}))}
                errorMessage={props.bookingInfoValidator.location}
            />
            Please select the date you would like to sit the test:
            <InputBookingDate 
                placeholder="dd/mm/yyyy"
                value={bookingInfo.date}
                consume={(n: any) => setBookingInfo(prevState => ({...prevState, date: n}))}
                checkIfValid={(b: boolean) => setValidBookingDate(b)}
                errorMessage={props.bookingInfoValidator.date}
            />
            {validBookingDate ? 
            (<>
                <Select
                    key={`test-time-select-${selectKey}`}
                    consume={(n: any) => {
                        setBookingInfo(prevState => ({...prevState, testTime: n.split(' - ')[0]}))
                    }}
                    placeholder="Select Option"
                    initialValue={bookingInfo.testTime}
                    values={timeSlots?.map((slot: any) => `${slot.time} - ${slot.capacity} available spots`) || []}
                    disabledValues={unavailableTimes?.map((slot: any) => `${slot.time} - ${slot.capacity} available spots`) || []}
                    errorMessage={props.bookingInfoValidator.testTime}
                />
            </>) : <></>}
        </>
    )
}
    