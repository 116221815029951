import { useLocation } from "react-router";
import { RightOuterLayer, MiddleSection, MiddleSectionHeader, BottomSection } from "../FormComponents/FormSections";
import { PreviousButton, NextButton } from "../FormComponents/Buttons";
import { useNavigate } from "react-router";
import { PREBOOKING_FORM } from "../../routes/routes";
import React, { useContext, useEffect, useState } from "react";
import { FormStage } from "../FormComponents/FormStage";
import { SchoolSection } from "../FormSections/SchoolSection";
import { GuardianFormSection } from "../FormSections/GuardianFormSection";
import { ConfirmationSection } from "../FormSections/ConfirmationSection";
import { StudentSection } from '../FormSections/StudentSection';
import { EntranceTestBookingSection } from "../FormSections/EntranceTestBookingSection";
import { ConfirmationInfo, ConfirmationInfoValidator } from '../../models/ConfirmationInfo';
import { EntranceTestBookingInfo, EntranceTestBookingInfoValidator } from "../../models/EntranceTestBookingInfo";
import { GuardianInfo, GuardianInfoValidator } from '../../models/GuardianInfo';
import { StudentInfo, StudentInfoValidator } from "../../models/StudentInfo";
import { SchoolInfo, SchoolInfoValidator } from '../../models/SchoolInfo';
import { CreateStudentWithEntranceTest } from "../../models/CreateStudentWithEntranceTest";
import entryTestClient from "../../client/EntryTestClient";
import { PaymentInfo, PaymentInfoValidator } from '../../models/PaymentInfo';
import { PaymentSection } from "../FormSections/PaymentSection";
import Swal from "sweetalert2";
import entranceTestBookingResponse from "../../models/entranceTestBookingResponse";
import { TextContext } from "../ContextManagers/TextContext";
import { VoucherSection } from "../FormSections/VoucherSection";
import { VoucherInfo } from "../../models/VoucherInfo";
import { PreBookingSystem } from "./PreBookingSystem";

export const EntranceTestRequired = (props: {
    internal: boolean;
    subjectOne: string;
    subjectTwo: string;
    subjectThree: string;
    subjectFour: string;
}) => {
    const { text, setText } = useContext(TextContext);
    const navigate = useNavigate();

    // Used to set the stage of the form
    const [formStage, setFormStage] = React.useState(FormStage.BOOKING)

    const subjectOne =  props.subjectOne
    const subjectTwo = props.subjectTwo
    const subjectThree = props.subjectThree
    const subjectFour = props.subjectFour


    // For the booking section of the form
    const [bookingInfo, setBookingInfo] = React.useState<EntranceTestBookingInfo>({
        location: 'Burwood',
        date: '',
        testTime: 'Select Option',
    })
    const [bookingFormComplete, setBookingFormComplete] = React.useState(false)

    // For the student section of the form
    const [studentInfo, setStudentInfo] = React.useState<StudentInfo>({
        name: '',
        lastName: '',
        gender: '',
        dateOfBirth: '',
        address: '',
        suburb: '',
        postcode: '',
        phoneNumber: '',
    })
    const [studentFormComplete, setStudentFormComplete] = React.useState(false)

    // For the school section of the form
    const [schoolInfo, setSchoolInfo] = React.useState<SchoolInfo>({
        school: '',
        year: '',
        name: '',
        otherSchoolName: '',
        number: ''
    })
    const [schoolFormComplete, setSchoolFormComplete] = React.useState(false)
    
    
    // For the guardian section of the form
    const [guardianInfo, setGuardianInfo] = React.useState<GuardianInfo>({
        name: '',
        familyName: '',
        number: '',
        email: '',
        relationship: 'Select Option',
        relationshipDetails: '',
        gender: ''
    });
    const [guardianFormComplete, setGuardianFormComplete] = React.useState(false);


    // For the confirmation section of the form
    const [confirmationInfo, setConfirmationInfo] = React.useState<ConfirmationInfo>({
        subjectOne: subjectOne,
        subjectTwo: subjectTwo,
        subjectThree: subjectThree,
        subjectFour: subjectFour,
        subjectConfirmation: false,
        studentConfirmation: false,
        parentConfirmation: false,
        contactConfirmation: false
    })
    const [confirmationFormComplete, setConfirmationFormComplete] = React.useState(false)

    const [voucherInfo, setVoucherInfo] = React.useState<VoucherInfo>({
        voucherCode: ''
    })

    const [paymentInfo, setPaymentInfo] = React.useState<PaymentInfo>({
        fullName: '',
        cardNumber: '',
        expiryDate: '',
        CVC: ''
    });
    
    const [paymentInfoValid, setPaymentInfoValid] = React.useState(false);

    const [isLoading, setIsLoading] = React.useState(false);


    const processPaymentAndStudentDetails = async (
        studentInfo: StudentInfo,
        schoolInfo: SchoolInfo,
        bookingInfo: EntranceTestBookingInfo,
        guardianInfo: GuardianInfo,
        confirmationInfo: ConfirmationInfo,
        paymentInfo: PaymentInfo,
        voucherInfo: VoucherInfo
        ) => {
        Swal.fire({
            title: 'Your Details Are Being Processed...',
            text: 'Please be patient, this may take up to 1 minute',
            allowOutsideClick: false, // Prevents user from closing the SWAL by clicking outside
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading(); // Shows the loading animation
            },
        });

        try {
            const response: entranceTestBookingResponse  = await entryTestClient.createStudentRecordWithEntranceTest(
                studentInfo,
                schoolInfo,
                bookingInfo,
                guardianInfo,
                confirmationInfo,
                paymentInfo,
                voucherInfo,
                props.internal
            )
            
            Swal.close();

            const paymentProcessed: boolean = response.paymentProcessed;
            const studentDetailsAdded: boolean = response.studentDetailsAdded;
            const entranceTestBooked: boolean = response.entranceTestBooked;
            const emailSent: boolean = response.emailSent;

            if (paymentProcessed === false) {
                Swal.fire({
                    title: "Payment not made",
                    text: "Payment details or voucher code entered incorrectly, please make sure they are correct and try again",
                    icon: "error",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    confirmButtonText: "Ok"
                })
            } else if (studentDetailsAdded === false) {
                Swal.fire({
                    title: "Student Details not added",
                    text: "Student details entered incorrectly please edit them and try again",
                    icon: "error",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    confirmButtonText: "Ok"
                })

            } else if (entranceTestBooked === false) {
                Swal.fire({
                    title: "Entrance Test not booked",
                    text: "Could not make Entrance Test Booking, please try again",
                    icon: "error",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    confirmButtonText: "Ok"
                })
            } else if (emailSent === false) {
                Swal.fire({
                    title: "Entrance Test not booked",
                    text: "Could not send Entrance Test Booking Email, please change your details and try again",
                    icon: "error",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    confirmButtonText: "Ok"
                })
            } else {
                // Need to do the await create student record
                Swal.fire({
                    title: "Booking made successfully",
                    text: "Payment for Entrance Test has been made. We will be in touch with you shortly",
                    icon: "success",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    confirmButtonText: "Ok"
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.href = 'https://www.drdueducation.com.au';
                    }
                })
            }

        } catch (err) {
            // Need to do the await create student record
            Swal.fire({
                title: "Booking was not successful, please try again",
                text: `${err}`,
                icon: "error",
                confirmButtonText: "Ok"
            })
        }
    }


    const [bookingInfoValidator, setBookingInfoValidator] = React.useState<EntranceTestBookingInfoValidator>({
        location: undefined,
        date: undefined,
        testTime: undefined
    })
    
    const bookingFormStage = () => {
        const handleBookingButton = () => {
            setBookingInfoValidator(prevState => ({
                ...prevState, 
                location: undefined,
                date: undefined,
                testTime: undefined
            }))

            if (bookingFormComplete) {
                setFormStage(FormStage.STUDENT)
            } else {
                const validations: Record<keyof EntranceTestBookingInfo, string> = {
                    location: "Please select a centre",
                    date: "Please select a date",
                    testTime: "Please select a test time"
                };

                
                // Iterate over each field to set the validation message if necessary
                Object.entries(validations).forEach(([field, message]) => {
                    const value = bookingInfo[field as keyof EntranceTestBookingInfo];
                    if (value === null || value.trim() === '' || (field.trim() === 'testTime' && value.trim() === 'Select Option')) {
                        setBookingInfoValidator(prevState => ({
                            ...prevState,
                            [field]: message
                        }));
                    }
                });
            }
        }

        return (
            <div>
                <RightOuterLayer progress={28}>
                    <MiddleSection>
                        <div>
                            <MiddleSectionHeader>
                                <strong>2/7</strong>Entrance Test Booking
                            </MiddleSectionHeader>
                            <EntranceTestBookingSection  
                                bookingInfo={bookingInfo}
                                consume={(b: EntranceTestBookingInfo) => {setBookingInfo(b)}}
                                checkForCompletion={(b: boolean) => setBookingFormComplete(b)}
                                bookingInfoValidator={bookingInfoValidator}
                            />    
                        </div>
                    </MiddleSection>
                    <BottomSection>
                        <i>Note: All fields are compulsory unless marked optional.</i>
                        <br />
                        <br />
                        <PreviousButton text="Previous" consume={() => window.location.reload()}  />
                        <NextButton text="Next" consume={() => {handleBookingButton()}} />
                    </BottomSection>
                </RightOuterLayer>
            </div>
        )
    }

    
    const [studentInfoValidator, setStudentInfoValidator] = React.useState<StudentInfoValidator>({
        name: undefined,
        lastName: undefined,
        gender: undefined,
        dateOfBirth: undefined,
        address: undefined,
        suburb: undefined,
        postcode: undefined,
        phoneNumber: undefined
    })

    const studentFormStage = () => {
        const handleStudentButton = () => {
            setStudentInfoValidator((prevState: StudentInfoValidator) => ({
                ...prevState,
                name: undefined,
                lastName: undefined,
                gender: undefined,
                dateOfBirth: undefined,
                address: undefined,
                suburb: undefined,
                postcode: undefined,
                phoneNumber: undefined
            }));

            if (studentFormComplete) {
                setFormStage(FormStage.SCHOOL)
            } else {
                // Define a mapping of field names to their validation messages
                const fieldValidations: Record<keyof StudentInfo, string> = {
                    name: "Please enter student given name",
                    lastName: "Please enter student family name",
                    gender: "Please select a gender",
                    dateOfBirth: "Please select a date of birth",
                    address: "Please enter address",
                    suburb: "Please select a suburb",
                    postcode: "Please enter a postcode",
                    phoneNumber: "Please enter valid student phone Number"
                };

                Object.entries(fieldValidations).forEach(([field, message]) => {
                    const value = studentInfo[field as keyof StudentInfo].trim();
                    if (value === '') { // Checks for empty strings
                        setStudentInfoValidator((prevState: StudentInfoValidator) => ({
                            ...prevState,
                            [field]: message
                        }));
                    }
                });
            }
        }
        return (
            <div>
                <RightOuterLayer progress={42}>
                    <MiddleSection>
                        <div>
                            <MiddleSectionHeader>
                                <strong>3/7</strong>Student Details
                            </MiddleSectionHeader>
                            <StudentSection
                                entranceTestInfo={studentInfo}
                                consume={(e: StudentInfo) => {setStudentInfo(e)}}
                                checkForCompletion={(b: boolean) => setStudentFormComplete(b)}
                                studentInfoValidator={studentInfoValidator}
                            />
                        </div>
                    </MiddleSection>
                    <BottomSection>
                        <i>Note: All fields are compulsory unless marked optional.</i>
                        <br />
                        <br />
                        <PreviousButton text="Previous" consume={() => setFormStage(FormStage.BOOKING)}  />
                        <NextButton text="Next" consume={() => {handleStudentButton()}} />
                    </BottomSection>
                </RightOuterLayer>
            </div>
        )
    }

    const [schoolInfoValidator, setSchoolInfoValidator] = React.useState<SchoolInfoValidator>({
        school: undefined,
        otherSchoolName: undefined,
        year: undefined,
        name: undefined,
        number: undefined
    })
    const schoolFormStage = () => {
        const handleSchoolButton = () => {
            setSchoolInfoValidator((prevState: SchoolInfoValidator) => ({
                ...prevState,
                school: undefined,
                otherSchoolName: undefined,
                year: undefined,
                name: undefined,
                number: undefined
            }));

            if (schoolFormComplete) {
                setFormStage(FormStage.GUARDIAN)
            } else {
                // Define a mapping of field names to their validation messages
                const fieldValidations: Record<keyof SchoolInfo, string> = {
                    school: "Please select a school",
                    year: "Please select student grade",
                    otherSchoolName: "Please enter other school name",
                    name: "Please enter school card name",
                    number: "Please enter school card number (32 character limit)"
                };

                Object.entries(fieldValidations).forEach(([field, message]) => {
                    const value = schoolInfo[field as keyof SchoolInfo].trim()
                    if (value === '' || (value === 'Select Current Year' && field === 'year') || (field === 'number' && value.length > 32)) { // Checks for empty strings
                        setSchoolInfoValidator((prevState: SchoolInfoValidator) => ({
                            ...prevState,
                            [field]: message
                        }));
                    }
                });
            }
        }

        return (
            <div>
                <RightOuterLayer progress={56}>
                    <MiddleSection>
                        <div>
                            <MiddleSectionHeader>
                                <strong>4/7</strong>Student School Details
                            </MiddleSectionHeader>
                            <SchoolSection 
                                schoolInfo={schoolInfo}
                                consume={(val: SchoolInfo) => {setSchoolInfo(val)}}
                                checkForCompletion={(b: boolean) => setSchoolFormComplete(b)}
                                schoolInfoValidator={schoolInfoValidator}
                            />
                        </div>
                    </MiddleSection>
                    <BottomSection>
                        <i>Note: All fields are compulsory unless marked optional.</i>
                        <br />
                        <br />
                        <PreviousButton text="Previous" consume={() => setFormStage(FormStage.STUDENT)}  />
                        <NextButton text="Next" consume={() => {handleSchoolButton()}} />
                    </BottomSection>
                </RightOuterLayer>
            </div>
        )
    }

    const [guardianInfoValidator, setGuardianInfoValidator] = useState<GuardianInfoValidator>({
        name: undefined,
        familyName: undefined,
        number: undefined,
        gender: undefined,
        email: undefined,
        relationship: undefined,
        relationshipDetails: undefined
    })
    
    const guardianFormStage = () => {
        const handleGuardianButton = () => {
            setGuardianInfoValidator((prevState: GuardianInfoValidator) => ({
                ...prevState,
                name: undefined,
                familyName: undefined,
                number: undefined,
                gender: undefined,
                email: undefined,
                relationship: undefined,
                relationshipDetails: undefined
            }));

            if (guardianFormComplete) {
                setFormStage(FormStage.CONFIRMATION)
                setText("Please confirm the details of your entrance test booking.")
            } else {
                // Define a mapping of field names to their validation messages
                const fieldValidations: Record<keyof GuardianInfo, string> = {
                    name: "Please enter guardian given name",
                    familyName: "Please enter guardian family name",
                    number: "Please enter guardian number",
                    gender: "Please select a gender",
                    email: "Please enter guardian email",
                    relationship: "Please select guardian relationship to student",
                    relationshipDetails: "Please enter relationship details",
                };

                Object.entries(fieldValidations).forEach(([field, message]) => {
                    const value = guardianInfo[field as keyof GuardianInfo].trim();
                    if (value === '' || (field === 'relationship' && value === 'Select Option')) { // Checks for empty strings
                        setGuardianInfoValidator((prevState: GuardianInfoValidator) => ({
                            ...prevState,
                            [field]: message
                        }));
                    }
                });
            }
        }

        return (
            <div>
                <RightOuterLayer progress={70}>
                    <MiddleSection>
                        <div>
                            <MiddleSectionHeader>
                                <strong>5/7</strong>Guardian Details
                            </MiddleSectionHeader>
                            <GuardianFormSection 
                                guardianInfo={guardianInfo}
                                consume={(e: GuardianInfo) => setGuardianInfo(e)}
                                checkForCompletion={(b: boolean) => setGuardianFormComplete(b)}
                                guardianInfoValidator={guardianInfoValidator}
                            />
                        </div>
                    </MiddleSection>
                    <BottomSection>
                        <i>Note: All fields are compulsory unless marked optional.</i>
                        <br />
                        <br />
                        <PreviousButton text="Previous" consume={() => {
                            setFormStage(FormStage.SCHOOL)
                        }}  />
                        <NextButton text="Next" consume={() => {handleGuardianButton()}} />
                    </BottomSection>
                </RightOuterLayer>
            </div>
        )
    }
    
    // Needs to placed here because the Next Button in the confirmation section will ensure that the fields
    // in paymentDetailsTouched are set to false
    const [paymentDetailsTouched, setPaymentDetailsTouched] = React.useState<PaymentInfoValidator>({
        fullName: false,
        cardNumber: false,
        expiryDate: false,
        CVC: false
    })

    const confirmationFormStage = () => {
        return (
                <div>
                    <RightOuterLayer progress={84}>
                        <MiddleSection>
                            <div>
                                <MiddleSectionHeader>
                                    <strong>6/7</strong>Booking Details
                                </MiddleSectionHeader>
                                <ConfirmationSection 
                                    confirmationInfo={confirmationInfo}
                                    guardianInfo={guardianInfo}
                                    studentInfo={studentInfo}
                                    consume={(c: ConfirmationInfo) => setConfirmationInfo(c)}
                                    consumeGuardianInfo={(g: GuardianInfo) => setGuardianInfo(g)}
                                    consumeStudentInfo={(s: StudentInfo) => setStudentInfo(s)}
                                    checkForCompletion={(b: boolean) => setConfirmationFormComplete(b)}
                                    bookingType="TEST"
                                />
                            </div>
                        </MiddleSection>
                        <BottomSection>
                            <i>Note: All fields are compulsory unless marked optional.</i>
                            <br />
                            <br />
                        <PreviousButton text="Previous" consume={() => {
                            setFormStage(FormStage.GUARDIAN)
                            setText("Based on your course selection(s), an entrance test is required prior to your enrolment. <br /> Please book your test using the form on the right.")
                        }}  />
                        {confirmationFormComplete ? 
                            (<NextButton text="Next" consume={() => {
                                setFormStage(FormStage.PAYMENT)
                                if (props.internal) {
                                    setText("Your booking is almost complete")
                                } else {
                                    setText("Please enter your payment details to finalise your booking")
                                }
                                setPaymentDetailsTouched(prevState => ({
                                    ...prevState,
                                    fullName: false,
                                    CVC: false,
                                    expiryDate: false,
                                    cardNumber: false
                                }))
                            }} />) : 
                            (<NextButton text="Next" consume={() => {
                                Swal.fire({
                                    title: "Please confirm your details",
                                    text: "Please ensure all confirmation boxes are selected and the contact details are valid and finalised",
                                    icon: "error",
                                    confirmButtonText: "Ok"
                                })
                            }} />
                        )}
                        </BottomSection>
                    </RightOuterLayer>
                </div>
        )
    }

    const [paymentInfoValidator, setPaymentInfoValidator] = React.useState<PaymentInfoValidator>({
        fullName: false,
        cardNumber: false,
        expiryDate: false,
        CVC: false
    })

    const [validVoucher, setValidVoucher] = React.useState(false);

    React.useEffect(() => {
        setPaymentInfoValidator((prevState: PaymentInfoValidator) => ({
            ...prevState,
            fullName: undefined,
            cardNumber: undefined,
            expiryDate: undefined,
            CVC: undefined
        }));
        checkValidPaymentDetails()
    }, [paymentInfo])

    const checkValidPaymentDetails = () => {
        const fieldValidations: Record<keyof PaymentInfo, string> = {
            fullName: "Please enter card owner name",
            cardNumber: "Please enter a valid card number",
            expiryDate: "Please enter card expiry date",
            CVC: "Please enter card CVC"
        };

        Object.entries(fieldValidations).forEach(([field, message]) => {
            const value = paymentInfo[field as keyof PaymentInfo].trim();
            if (value === '' || !value || (field.trim() === 'expiryDate' && value.trim() === '\/')) { // Checks for empty strings
                setPaymentInfoValidator((prevState: PaymentInfoValidator) => ({
                    ...prevState,
                    [field]: message
                }));

            }
        });
    }


    const paymentFormStage = () => {
        const getPaymentButton = () => {
            const handlePaymentButton = async () => {
                setPaymentInfoValidator((prevState: PaymentInfoValidator) => ({
                    ...prevState,
                    fullName: undefined,
                    cardNumber: undefined,
                    expiryDate: undefined,
                    CVC: undefined
                }));
    
                if (isLoading) {return;}
    
                setIsLoading(true);
    
                await processPaymentAndStudentDetails(
                    studentInfo,
                    schoolInfo,
                    bookingInfo,
                    guardianInfo,
                    confirmationInfo,
                    paymentInfo,
                    voucherInfo
                )
    
                setIsLoading(false);
            };

            return (
                <NextButton 
                    text={isLoading ? "Processing Details" : "Finish"}
                    consume={() => {handlePaymentButton()}} />
            )
        }

        const incompletePaymentButton = () => {
            const handleIncompletePayment = async () => {
                setPaymentInfoValidator((prevState: PaymentInfoValidator) => ({
                    ...prevState,
                    fullName: undefined,
                    cardNumber: undefined,
                    expiryDate: undefined,
                    CVC: undefined
                }));

                setPaymentDetailsTouched(prevState => ({
                    ...prevState,
                    fullName: true,
                    CVC: true,
                    expiryDate: true,
                    cardNumber: true
                }))
                
                if (isLoading) return;
                setIsLoading(true);
                checkValidPaymentDetails()
                setIsLoading(false);
            }
            return (
                <NextButton 
                    text={isLoading ? "Payment is processing" : "Finish"}
                    consume={() => {handleIncompletePayment()}} />
            )
        }

        const paymentSectionValid = () => {
            return paymentInfoValid || validVoucher;
        }

        const ExternalPaymentForm = () => {
            return (
                <div >
                    <RightOuterLayer progress={100}>
                        <MiddleSectionHeader>
                            <strong>7/7</strong>Please enter a your payment details we accept Visa or Mastercard
                        </MiddleSectionHeader>
                        <MiddleSection>
                            <div>
                                <VoucherSection 
                                    voucherInfo={voucherInfo}
                                    consume={(c: VoucherInfo) => setVoucherInfo(c)}
                                    checkValidVoucher={(b: boolean) => setValidVoucher(b)}
                                />
                                {
                                    !validVoucher && 
                                    (
                                        <>
                                        
                                            <h6>Total cost for the Entrance Test will be $25</h6>
                                            
                                            <figure>
                                                {<img src="./images/visa-mc.png" className="img-fluid" width={350} height={350} />}
                                            </figure>
                                            <PaymentSection
                                                paymentInfo={paymentInfo}
                                                consume={(p: PaymentInfo) => setPaymentInfo(p)}
                                                consumePaymentTouched={(p: PaymentInfoValidator) => setPaymentDetailsTouched(p)}
                                                checkForCompletion={(b: boolean) => setPaymentInfoValid(b)} 
                                                paymentInfoValidator={paymentInfoValidator}
                                                paymentDetailsTouched={paymentDetailsTouched}
                                            />
                                        </>
                                    )
                                }
                            </div>
                        </MiddleSection>
                        <BottomSection>
                            <i>Note: All fields are compulsory unless marked optional.</i>
                            <br />
                            <br />
                        <PreviousButton 
                            text="Previous" 
                            consume={() => {
                                setFormStage(FormStage.CONFIRMATION)
                                setText("Please confirm the details of your entrance test booking.")
                            }}  
                        />
                        {paymentSectionValid() ? getPaymentButton() : incompletePaymentButton()}
                        </BottomSection>
                    </RightOuterLayer>
                </div>
            )
        }

        const InternalPaymentForm = () => {
            return (
                <div>
                    <RightOuterLayer progress={100}>
                        <MiddleSectionHeader>
                            <strong>7/7</strong>Thank you for filling out your details
                        </MiddleSectionHeader>
                        Please make payment of $25 cash to the front desk and click finish complete your booking
                        <MiddleSection>
                        </MiddleSection>
                        <BottomSection>
                        <PreviousButton 
                            text="Previous" 
                            consume={() => {
                                setFormStage(FormStage.CONFIRMATION)
                                setText("Please confirm the details of your entrance test booking.")
                            }}  
                        />
                        {getPaymentButton()}
                        </BottomSection>
                    </RightOuterLayer>
                </div>
            )

        }

        return (
            <>
                {props.internal && InternalPaymentForm()}
                {!props.internal && ExternalPaymentForm()}
            </>
        )
    }

    const getCurrentFormStage = () => {
        switch (formStage) {
            case FormStage.BOOKING:
                return (
                   <>{bookingFormStage()}</>
                )

            case FormStage.STUDENT:
                return (
                   <>{studentFormStage()}</>
                )
            
            case FormStage.SCHOOL:
                return (
                   <>{schoolFormStage()}</>
                )

            case FormStage.GUARDIAN:
                return (
                   <>{guardianFormStage()}</>
                )

            case FormStage.CONFIRMATION:
                return (
                   <>{confirmationFormStage()}</>
                )
                
            case FormStage.PAYMENT:
                return (
                   <>{paymentFormStage()}</>
                )
        }
    }

    return (
        <>
            {getCurrentFormStage()}
        </>
    )
}