import { useState, useEffect } from "react";
import { InputPhoneNumberBox, InputPostcodeBox, InputTextBox, InputBirthDate, InputTextBoxWithValidation, InputAddressBoxWithValidation } from "../FormComponents/InputTextBox";
import { SelectCheckbox } from "../FormComponents/CheckBox";
import { validatePostcode, validatePhoneNumber, validateBirthday, validateGenericName, validateGenericAddress } from "../../utils/validatorUtils";
import entryTestClient from "../../client/EntryTestClient";
import {  SelectWithSearch } from "../FormComponents/Select";
import { StudentInfo, StudentInfoValidator } from '../../models/StudentInfo';

export const StudentSection = (props: {
    entranceTestInfo: StudentInfo;
    consume: (val: StudentInfo) => void;
    checkForCompletion: (val: boolean) => void;
    studentInfoValidator: StudentInfoValidator;
}) => {
    const [validName, setValidName] = useState(validateGenericName(props.entranceTestInfo.name))
    const [validLastName, setValidLastName] = useState(validateGenericName(props.entranceTestInfo.lastName))
    const [validAddress, setValidAddress] = useState(validateGenericAddress(props.entranceTestInfo.address))
    const [validPostcode, setValidPostcode] = useState(validatePostcode(props.entranceTestInfo.postcode));
    const [validPhoneNumber, setValidPhoneNumber] = useState(validatePhoneNumber(props.entranceTestInfo.phoneNumber));
    const [validBirthDate, setValidBirthDate] = useState(validateBirthday(props.entranceTestInfo.dateOfBirth));
    const [studentInfo, setStudentInfo] = useState<StudentInfo>(props.entranceTestInfo)
    const [suburbs, setSuburbs] = useState([])

    const [studentInfoValidator, setStudentInfoValidator] = useState<StudentInfoValidator>(props.studentInfoValidator) 

    useEffect(
        () => {
            const obtainSuburbs = async () => {
                try {
                    const subs = await entryTestClient.getSuburbs();
                    setSuburbs(subs);
                } catch (error) {
                    console.error("Failed to fetch suburbs:", error);
                }
            };
            obtainSuburbs()
        }
    , [])

    const studentStageComplete = () => {
        if (
            studentInfo.name === (null || '')
            || studentInfo.lastName === ('' ||null || '')
            || studentInfo.gender === (null || '')
            || studentInfo.dateOfBirth === (null || '')
            || studentInfo.address === (null || '')
            || studentInfo.suburb === (null || '')
            || studentInfo.postcode === (null || '')
            || studentInfo.phoneNumber === (null || '')
        ) {
            return false;
        }

        if (!validPostcode || !validPhoneNumber || !validBirthDate || !validName || !validLastName || !validAddress) { 
            return false; 
        }
        
        return true;
    }
    
    useEffect(
        () => {
            props.consume(studentInfo)
            const formComplete = studentStageComplete()
            props.checkForCompletion(formComplete)
            
        }
    , [studentInfo])

    return (
        <>
            <InputTextBoxWithValidation 
                placeholder="Student Given Name"
                value={studentInfo.name}
                consume={(n) => setStudentInfo(prevState => ({...prevState, name: n}))}
                checkIfValid={(b: boolean) => setValidName(b)}
                errorMessage={props.studentInfoValidator.name}
                englishOnly={true}
                />
            <InputTextBoxWithValidation 
                placeholder="Student Family Name"
                value={studentInfo.lastName}
                consume={(n) => setStudentInfo(prevState => ({...prevState, lastName: n}))}
                errorMessage={props.studentInfoValidator.lastName}
                checkIfValid={(b: boolean) => setValidLastName(b)}
                englishOnly={true}
                />
            Student Gender:
            <SelectCheckbox 
                optionOne="Male" 
                optionTwo="Female"  
                value={studentInfo.gender}
                consume={(n) => setStudentInfo(prevState => ({...prevState, gender: n}))}
                errorMessage={props.studentInfoValidator.gender}
                />
            Please select your date of birth (click calendar icon):
            <InputBirthDate 
                placeholder="dd/mm/yyyy"
                value={studentInfo.dateOfBirth}
                consume={(n) => setStudentInfo(prevState => ({...prevState, dateOfBirth: n}))}
                checkIfValid={(b: boolean) => setValidBirthDate(b)}
                errorMessage={props.studentInfoValidator.dateOfBirth}
                />
            Student Address:
            <InputAddressBoxWithValidation 
                placeholder="Address"
                value={studentInfo.address}
                consume={(n) => setStudentInfo(prevState => ({...prevState, address: n}))}
                errorMessage={props.studentInfoValidator.address}
                checkIfValid={(b: boolean) => setValidAddress(b)}
                englishOnly={true}
                />
            <SelectWithSearch 
                consume={(n: any) => {
                    setStudentInfo(prevState => ({...prevState, suburb: n.split(" - ")[0]}))
                }}
                placeholder="Select Option"
                initialValue={(studentInfo.suburb === '' && studentInfo.postcode === '') ? 'Select Suburb' : `${studentInfo.suburb}`}
                values={suburbs?.filter((sub: any) => sub.state !== 'nan').map((sub: any) => `${sub.name} (${sub.state})`)}
                errorMessage={props.studentInfoValidator.suburb}
                />
            <InputPostcodeBox 
                placeholder="Postcode"
                value={studentInfo.postcode}
                consume={(n) => setValidPostcode(n)}
                updateValue={(n) => setStudentInfo(prevState => ({...prevState, postcode: n}))}
                errorMessage={props.studentInfoValidator.postcode}
                />
            Student Phone Number:
            <InputPhoneNumberBox 
                placeholder="Phone Number"
                value={studentInfo.phoneNumber}
                consume={(n) => setValidPhoneNumber(n)}
                updateValue={(n) => setStudentInfo(prevState => ({...prevState, phoneNumber: n}))}
                errorMessage={props.studentInfoValidator.phoneNumber}
                />
        </>
    )
}