import { ReactNode } from "react"
// PLVCL = Plagarised Layout Violating Copyright Laws
export const PLVCL = (props: {heading: string, text: string, children: ReactNode}) => {
    const createMarkup = () => {
        return {__html: props.text.replace(/\n/g, '<br />')};
    }
    
    return (
        <div className="container-fluid full-height">
            <div className="row row-height">
                <div className="col-lg-6 content-left">
                    <div className="content-left-wrapper">
                        <a href="index.html" id="logo" />
                        {/* /social */}
                        <div>
                            <figure>
                                {<img src="./images/drdu-logo.png" className="img-fluid" width={150} height={200} />}
                            </figure>
                            <h2>{props.heading}</h2>
                            <p dangerouslySetInnerHTML={createMarkup()} />
                            <a href="#start" className="btn_1 rounded mobile_btn">
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 content-right" id="start">
                    <div id="wizard_container" className="wizard">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}

