import React from "react";
import { InputTextBox, InputNumericBox } from "../FormComponents/InputTextBox";
import { studentYears as yearGroups } from "../FormComponents/Select";
import { Select, SelectWithSearch } from "../FormComponents/Select";
import entryTestClient from "../../client/EntryTestClient";
import { SchoolInfo, SchoolInfoValidator } from "../../models/SchoolInfo";

export const SchoolSection = ( props:{
    schoolInfo: SchoolInfo;
    consume: (val: SchoolInfo) => void;
    checkForCompletion: (val: boolean) => void;
    schoolInfoValidator: SchoolInfoValidator;
}) => {

    

    const [schoolInfo, setSchoolInfo] = React.useState<SchoolInfo>(props.schoolInfo)
    const [schools, setSchools] = React.useState<any>([])
    
    React.useEffect(
        () => {
            const obtainSchools = async () => {
                try {
                    const schs = await entryTestClient.getSchools();
                    const otherSchool = {
                        id: schs.length + 1,
                        name: 'Other',
                        shortName: 'Other',
                        verShortName: 'Other',
                    };
                    setSchools([...schs, otherSchool])
                } catch (error) {
                    console.error("Failed to fetch schools:", error);
                }
            };
            obtainSchools()
        }
    , [])



    const schoolStageComplete = () => {
        if (
            schoolInfo.school === (null || '')
            || (schoolInfo.year ===  ''||schoolInfo.year === 'Select Current Year')
            || schoolInfo.name === (null || '')
            || schoolInfo.number === (null || '')
        ) {
            return false;
        }
        if (schoolInfo.school === "Other" && schoolInfo.otherSchoolName === '') {return false;}
        if (schoolInfo.number.length > 32) {return false;}
        return true;
    }
    
    React.useEffect(
        () => {
            props.consume(schoolInfo)
            const formComplete = schoolStageComplete()
            props.checkForCompletion(formComplete)
        }
    , [schoolInfo])

    return (
        <>
            <div>
            Select 'Other' if your school cannot be found
            <SelectWithSearch 
                consume={(n: any) => {
                    // setStudentInfo(prevState => ({...prevState, suburb: n.split(" - ")[0], postcode: n.split(" - ")[1]}))
                    setSchoolInfo(prevState => ({...prevState, school: n}))
                }}
                placeholder="Select School"
                initialValue={schoolInfo.school}
                values={schools?.filter((val: any) => val.name !== 'AAA School').map((sch: any) => sch.name)}
                errorMessage={props.schoolInfoValidator.school}
                />
            {schoolInfo.school === "Other" ? 
            (
                <InputTextBox 
                    placeholder="Please Enter Your School Name"
                    value={schoolInfo.otherSchoolName}
                    consume={(n) => setSchoolInfo(prevState => ({...prevState, otherSchoolName: n}))}
                    errorMessage={props.schoolInfoValidator.otherSchoolName}

                    />) : 
            (<></>)}
                
            <Select
                consume={(n) => setSchoolInfo(prevState => ({...prevState, year: n}))}
                placeholder="Select Current Year"
                values={yearGroups}
                initialValue={schoolInfo.year}
                errorMessage={props.schoolInfoValidator.year}
                />
            </div>
            <b>School ID Details:</b>
            <br />
            Please enter your Student Name and Student Number exactly as they appear on your School Student ID card.
            <br />
            <br />
            <InputTextBox 
                placeholder="Student Name"
                value={schoolInfo.name}
                consume={(n) => setSchoolInfo(prevState => ({...prevState, name: n}))}
                errorMessage={props.schoolInfoValidator.name}
                englishOnly={true}
                />
            <InputTextBox 
                placeholder="Student Number"
                value={schoolInfo.number}
                consume={(n) => setSchoolInfo(prevState => ({...prevState, number: n}))}
                errorMessage={props.schoolInfoValidator.number}
                englishOnly={true}
                />
        </>
    )
} 