import { GuardianInfo } from "../models/GuardianInfo";
import { SchoolInfo } from "../models/SchoolInfo";
import { StudentInfo } from "../models/StudentInfo";
import { NoEntranceTestBookingInfo } from "../models/NoEntranceTestBookingInfo";
import { EntranceTestBookingInfo } from "../models/EntranceTestBookingInfo";
import { CreateStudentWithEntranceTest } from '../models/CreateStudentWithEntranceTest';
import { CreateStudentWithNoEntranceTest } from '../models/CreateStudentWithNoEntranceTest';
import { ConfirmationInfo } from '../models/ConfirmationInfo';
import { PaymentInfo } from '../models/PaymentInfo';
import { VoucherInfo } from '../models/VoucherInfo';

export function capitalizeAndRemoveWhiteSpace(input: string) {
    // Split the string into words, capitalize each word if it is alphabetic and then join them
    const capitalized = input
        .trim() // trim input to remove to remove leading and trailing whitespace
        .replace(/'/g, '')
        .split(/\s+/) // split by whitespace
        .map(word => {
            // Check if the word is alphabetic
            if (isNaN(Number(word))) {
                // Capitalize the first letter and keep the rest of the word in lowercase
                return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            }
            return word;
        })
        .join(' ');
    return capitalized
}

export function createStudentRecordForEntranceTestFromInterface(
    studentInfo: StudentInfo,
    schoolInfo: SchoolInfo,
    bookingInfo: EntranceTestBookingInfo,
    guardianInfo: GuardianInfo,
    confirmationInfo: ConfirmationInfo,
    paymentInfo: PaymentInfo,
    voucherInfo: VoucherInfo
    ) {
        const record: CreateStudentWithEntranceTest = {
            // Student Info
            studentName: capitalizeAndRemoveWhiteSpace(studentInfo.name),
            studentLastName: capitalizeAndRemoveWhiteSpace(studentInfo.lastName),
            studentGender: studentInfo.gender,
            studentDateOfBirth: studentInfo.dateOfBirth,
            studentAddress: capitalizeAndRemoveWhiteSpace(studentInfo.address),
            studentSuburb: studentInfo.suburb,
            studentPostcode: studentInfo.postcode,
            studentPhoneNumber: studentInfo.phoneNumber,

            // Booking Info With Entrance Test
            bookingLocation:  bookingInfo.location,
            bookingDate:  bookingInfo.date,
            bookingTestTime:  bookingInfo.testTime,
            bookingContactNumberOne: guardianInfo.number,
            bookingRelationshipOne: guardianInfo.relationship,
            bookingOtherOne: guardianInfo.relationshipDetails,
            bookingContactNumberTwo: '',
            bookingRelationshipTwo: '',
            bookingOtherTwo: '',

            // Guardian Info
            guardianName: capitalizeAndRemoveWhiteSpace(guardianInfo.name),
            guardianFamilyName: capitalizeAndRemoveWhiteSpace(guardianInfo.familyName),
            guardianNumber: guardianInfo.number,
            guardianEmail: guardianInfo.email,
            guardianRelationship: guardianInfo.relationship,
            guardianRelationshipDetails: guardianInfo.relationshipDetails,
            guardianGender: guardianInfo.gender,

            // School Info
            school: schoolInfo.school,
            otherSchoolName: schoolInfo.otherSchoolName,
            year: schoolInfo.year,
            schoolCardName: capitalizeAndRemoveWhiteSpace(schoolInfo.name),
            schoolCardNumber:  capitalizeAndRemoveWhiteSpace(schoolInfo.number),

            // Confirmation Info
            subjectOne: confirmationInfo.subjectOne === 'Select Subject One' ? null : confirmationInfo.subjectOne, 
            subjectTwo: confirmationInfo.subjectTwo === 'Select Subject Two (Optional)' ? null : confirmationInfo.subjectTwo,
            subjectThree: confirmationInfo.subjectThree === 'Select Subject Three (Optional)' ? null : confirmationInfo.subjectThree,
            subjectFour: confirmationInfo.subjectFour === 'Select Subject Four (Optional)' ? null : confirmationInfo.subjectFour,
            subjectConfirmation: confirmationInfo.subjectConfirmation,
            studentConfirmation: confirmationInfo.studentConfirmation,
            parentConfirmation: confirmationInfo.parentConfirmation,
            
            // Payment Info
            cardOwnerName: paymentInfo.fullName,
            cardNumber: paymentInfo.cardNumber,
            cardExpiryDate: paymentInfo.expiryDate,
            cardCVN: paymentInfo.CVC,

            // Voucher Info
            voucherCode: voucherInfo.voucherCode
        }

    return record
}

export function createStudentRecordForNoEntranceTestFromInterface(
    studentInfo: StudentInfo,
    schoolInfo: SchoolInfo,
    guardianInfo: GuardianInfo,
    confirmationInfo: ConfirmationInfo
    ) {
        const record: CreateStudentWithNoEntranceTest = {
            // Student Info
            studentName: capitalizeAndRemoveWhiteSpace(studentInfo.name),
            studentLastName: capitalizeAndRemoveWhiteSpace(studentInfo.lastName),
            studentGender: studentInfo.gender,
            studentDateOfBirth: studentInfo.dateOfBirth,
            studentAddress: capitalizeAndRemoveWhiteSpace(studentInfo.address),
            studentSuburb: studentInfo.suburb,
            studentPostcode: studentInfo.postcode,
            studentPhoneNumber: studentInfo.phoneNumber,

            // Booking Info With No Entrance Test
            bookingContactNumberOne: guardianInfo.number,
            bookingRelationshipOne: guardianInfo.relationship,
            bookingOtherOne: guardianInfo.relationshipDetails,
            bookingContactNumberTwo: '',
            bookingRelationshipTwo: '',
            bookingOtherTwo: '',

            // Guardian Info
            guardianName: capitalizeAndRemoveWhiteSpace(guardianInfo.name),
            guardianFamilyName: capitalizeAndRemoveWhiteSpace(guardianInfo.familyName),
            guardianNumber: guardianInfo.number,
            guardianEmail: guardianInfo.email,
            guardianRelationship: guardianInfo.relationship,
            guardianRelationshipDetails: guardianInfo.relationshipDetails,
            guardianGender: guardianInfo.gender,

            // School Info
            school: schoolInfo.school,
            otherSchoolName: schoolInfo.otherSchoolName,
            year: schoolInfo.year,
            schoolCardName: capitalizeAndRemoveWhiteSpace(schoolInfo.name),
            schoolCardNumber:  capitalizeAndRemoveWhiteSpace(schoolInfo.number),

            // Confirmation Info
            subjectOne: confirmationInfo.subjectOne === 'Select Subject One' ? null : confirmationInfo.subjectOne, 
            subjectTwo: confirmationInfo.subjectTwo === 'Select Subject Two (Optional)' ? null : confirmationInfo.subjectTwo,
            subjectThree: confirmationInfo.subjectThree === 'Select Subject Three (Optional)' ? null : confirmationInfo.subjectThree,
            subjectFour: confirmationInfo.subjectFour === 'Select Subject Four (Optional)' ? null : confirmationInfo.subjectFour,
            subjectConfirmation: confirmationInfo.subjectConfirmation,
            studentConfirmation: confirmationInfo.studentConfirmation,
            parentConfirmation: confirmationInfo.parentConfirmation,
        }
        
    return record
}