import React from "react";
import { ReadOnlyInputTextBox, InputEmailTextBox, InputPhoneNumberBox } from "../FormComponents/InputTextBox";
import { CheckBox } from "../FormComponents/CheckBox";
import { ConfirmationInfo } from '../../models/ConfirmationInfo';
import { Modal, Form } from "react-bootstrap";
import { NextButton, PreviousButton } from "../FormComponents/Buttons";
import { subjectsRequiringTest, Select, subjects, studentYears } from '../FormComponents/Select';
import { GuardianInfo } from "../../models/GuardianInfo";
import { StudentInfo } from "../../models/StudentInfo";
import { validateEmail, validatePhoneNumber } from "../../utils/validatorUtils";

export const ConfirmationSection = (props: {
    confirmationInfo: ConfirmationInfo;
    guardianInfo: GuardianInfo;
    studentInfo: StudentInfo;
    consume: (val: ConfirmationInfo) => void;
    consumeGuardianInfo: (val: GuardianInfo) => void;
    consumeStudentInfo: (val: StudentInfo) => void;
    checkForCompletion: (val: boolean) => void;
    bookingType: "TEST" | "NO-TEST";
}) => {
    const [confirmationInfo,  setConfirmationInfo] = React.useState(props.confirmationInfo);
    const [studentInfo, setStudentInfo] = React.useState(props.studentInfo)
    const [guardianInfo, setGuardianInfo] = React.useState(props.guardianInfo)
    const [show, setShow] = React.useState(false)
    const [validGuardianEmail, setValidGuardianEmail] = React.useState(validateEmail(props.guardianInfo.email))
    const [validGuardianNumber, setValidGuardianNumber] = React.useState(validatePhoneNumber(props.guardianInfo.number))
    const [validStudentNumber, setValidStudentNumber] =  React.useState(validatePhoneNumber(props.studentInfo.phoneNumber))

    const confirmationStageComplete = () => {
        return (
            confirmationInfo.subjectConfirmation === true && 
            confirmationInfo.studentConfirmation === true && 
            confirmationInfo.parentConfirmation === true &&
            confirmationInfo.contactConfirmation === true &&
            validGuardianEmail === true &&
            validStudentNumber === true &&
            validGuardianNumber === true
        );
    }

    React.useEffect(() => {
        props.consume(confirmationInfo)
        props.consumeGuardianInfo(guardianInfo)
        props.consumeStudentInfo(studentInfo)
        props.checkForCompletion(confirmationStageComplete())

    }, [confirmationInfo, studentInfo, guardianInfo])

    
    const entranceTestSubjects = new Set<string>(subjectsRequiringTest)

    const subjectOneSelected = () => {
        return confirmationInfo.subjectOne !== "Select Subject One"
    }

    const duplicateSubjects = () => {
        const values = new Set([
            confirmationInfo.subjectOne,
            confirmationInfo.subjectTwo,
            confirmationInfo.subjectThree,
            confirmationInfo.subjectFour
        ])
        return values.size !== 4;
    }

    const requiredToSitTest = () => {
        if (confirmationInfo.subjectOne !== null &&  entranceTestSubjects.has(confirmationInfo.subjectOne)) {
            return true
        }
        if (confirmationInfo.subjectTwo !== null && entranceTestSubjects.has(confirmationInfo.subjectTwo)) {
            return true
        }
        if (confirmationInfo.subjectThree !== null && entranceTestSubjects.has(confirmationInfo.subjectThree)) {
            return true
        }
        if (confirmationInfo.subjectFour !== null && entranceTestSubjects.has(confirmationInfo.subjectFour)) {
            return true
        }
        return false
    }


    return (
        <>
            <b>Subject(s) Selected</b>
            <br />
            {props.confirmationInfo.subjectOne !== null ?
            (<ReadOnlyInputTextBox placeholder={props.confirmationInfo.subjectOne !== null ? 
                props.confirmationInfo.subjectOne.toString() : ''} ></ReadOnlyInputTextBox>) : (<></>)
            }
            {props.confirmationInfo.subjectTwo !== null && props.confirmationInfo.subjectTwo !== 'Select Subject Two (Optional)' ?
            (<ReadOnlyInputTextBox placeholder={props.confirmationInfo.subjectTwo !== null ? 
                props.confirmationInfo.subjectTwo.toString() : ''} ></ReadOnlyInputTextBox>) : (<></>)
            }
            {props.confirmationInfo.subjectThree !== null && props.confirmationInfo.subjectThree !== 'Select Subject Three (Optional)' ?
            (<ReadOnlyInputTextBox placeholder={props.confirmationInfo.subjectThree !== null ? 
                props.confirmationInfo.subjectThree.toString() : ''} ></ReadOnlyInputTextBox>) : (<></>)
            }
            {props.confirmationInfo.subjectFour !== null && props.confirmationInfo.subjectFour !== 'Select Subject Four (Optional)' ?
            (<ReadOnlyInputTextBox placeholder={props.confirmationInfo.subjectFour !== null ? 
                props.confirmationInfo.subjectFour.toString() : ''} ></ReadOnlyInputTextBox>) : (<></>)
            }
            {/* {
                !confirmationInfo.subjectConfirmation 
                &&
                <>
                    <NextButton text="Change Subject(s)" consume={() => {setShow(true)}} />
                    <br />
                    <br />
                </>
            } */}
            <CheckBox 
                text="I confirm that the selected subject(s) are correct." 
                consume={(n) => setConfirmationInfo(prevState => ({...prevState, subjectConfirmation: n}))}

            />
            {
                props.bookingType === "TEST" ? 
                (
                    <>
                        <b>Contact Details</b>
                        <br />
                        Confirmation of your entrance test will be sent to the following email and phone numbers.
                        <br />
                        <br />
                        Guardian Number: 
                        <br />
                        <InputPhoneNumberBox 
                            placeholder="Guardian Phone"
                            value={guardianInfo.number}
                            consume={(n) => setValidGuardianNumber(n)}
                            updateValue={(n) => setGuardianInfo(prevState => ({...prevState, number: n}))}
                        />
                        Guardian Email: 
                        <br />
                        <InputEmailTextBox 
                            placeholder="Guardian Email"
                            value={guardianInfo.email} 
                            consume={(n) => setValidGuardianEmail(n)}
                            updateValue={(n) => setGuardianInfo(prevState => ({...prevState, email: n}))}
                        />
                        Student Number:
                        <br />
                        <InputPhoneNumberBox 
                            placeholder="Student Phone"
                            value={studentInfo.phoneNumber}
                            consume={(n) => setValidStudentNumber(n)}
                            updateValue={(n) => setStudentInfo(prevState => ({...prevState, phoneNumber: n}))}
                        />
                        <CheckBox 
                            text="I confirm that the contact details are correct" 
                            consume={(n) => setConfirmationInfo(prevState => ({...prevState, contactConfirmation: n}))}
                        />
                    </>
                ) : 
                (
                    <>
                        <b>Contact Details</b>
                        <br />
                        Confirmation of your booking will be sent to this email:
                        <br />
                        <InputEmailTextBox 
                            placeholder="Guardian Email"
                            value={guardianInfo.email} 
                            consume={(n) => setValidGuardianEmail(n)}
                            updateValue={(n) => setGuardianInfo(prevState => ({...prevState, email: n}))}
                        />
                        <CheckBox 
                            text="I confirm that the contact details are correct" 
                            consume={(n) => setConfirmationInfo(prevState => ({...prevState, contactConfirmation: n}))}
                        />
                    </>

                )
            }
            
            <b>Policies and procedures</b> have been put in place to ensure that students have the best possible learning opportunity whilst studying at Dr. Du Education.
            <br />
            <br />
            Please read the <b><a href="https://www.drdueducation.com.au/terms-conditions/" target="_blank" rel="noopener noreferrer">Terms and Conditions of Enrolment</a></b> before booking your entrance test.
            <br />
            <br />
            <CheckBox 
                text="Student: I have read, understood and agree to the Terms and Conditions of Enrolment for Dr. Du Education." 
                consume={(n) => setConfirmationInfo(prevState => ({...prevState, studentConfirmation: n}))}
            />
            <CheckBox 
                text="Parents & guardians: I have read, understood and agree to the Terms and Conditions of Enrolment for Dr. Du Education." 
                consume={(n) => setConfirmationInfo(prevState => ({...prevState, parentConfirmation: n}))}
            />
            <div style={{overflowX: 'auto'}}>
                <Modal size="xl" show={show} backdrop={false} keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title><h5>Please Change Your Subjects</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form >
                            <Select 
                                consume={(subject: any) => setConfirmationInfo(prevState => ({...prevState, subjectOne: subject === '' ? 'Select Subject One' : subject}))}
                                placeholder={`${"Select Subject One"}`}
                                initialValue={`${confirmationInfo.subjectOne}`}
                                values={subjects}
                            />
                            <Select 
                                consume={(subject: any) => setConfirmationInfo(prevState => ({...prevState, subjectTwo: subject === '' ? 'Select Subject Two (Optional)' : subject}))}
                                placeholder={`${"Select Subject Two (Optional)"}`}
                                initialValue={`${confirmationInfo.subjectTwo}`}
                                values={subjects}
                            />
                            <Select 
                                consume={(subject: any) => setConfirmationInfo(prevState => ({...prevState, subjectThree: subject === '' ? 'Select Subject Three (Optional)' : subject}))}
                                placeholder={`${"Select Subject Three (Optional)"}`}
                                initialValue={`${confirmationInfo.subjectThree}`}
                                values={subjects}
                            />
                            <Select 
                                consume={(subject: any) => setConfirmationInfo(prevState => ({...prevState, subjectFour: subject === '' ? 'Select Subject Four (Optional)' : subject}))}
                                placeholder={`${"Select Subject Four (Optional)"}`}
                                initialValue={`${confirmationInfo.subjectFour}`}
                                values={subjects}
                            />
                            {
                                !subjectOneSelected() ?
                                    <PreviousButton text="Subject One must be selected" consume={() => {}}/>
                                :
                                <>
                                    {
                                        props.bookingType === "TEST" 
                                        ? 
                                            <>
                                                {
                                                    !requiredToSitTest() && 
                                                    !duplicateSubjects() && 
                                                    <PreviousButton text="Math Subject Required for this booking" consume={() => {}} />
                                                    
                                                } 
                                                {
                                                    !requiredToSitTest() && 
                                                    duplicateSubjects() && 
                                                    <PreviousButton text="Math Subject Required for this booking" consume={() => {}} />
                                                } 
                                                {
                                                    requiredToSitTest() && 
                                                    duplicateSubjects() && 
                                                    <PreviousButton text="Cannot select duplicate subjects" consume={() => {}} />
                                                } 
                                                {
                                                    requiredToSitTest() && 
                                                    !duplicateSubjects() && 
                                                    <NextButton text="Confirm Subject Selection" consume={() => {setShow(false)}} />
                                                } 
                                            </> 
                                        : 
                                            <>
                                                {
                                                    !requiredToSitTest() && 
                                                    !duplicateSubjects() && 
                                                    <NextButton text="Confirm Subject Selection" consume={() => {setShow(false)}} />
                                                    
                                                } 
                                                {
                                                    !requiredToSitTest() && 
                                                    duplicateSubjects() && 
                                                    <PreviousButton text="Cannot select duplicate subjects" consume={() => {}} />
                                                } 
                                                {
                                                    requiredToSitTest() && 
                                                    duplicateSubjects() && 
                                                    <PreviousButton text="Cannot select duplicate subjects" consume={() => {}} />
                                                } 
                                                {
                                                    requiredToSitTest() && 
                                                    !duplicateSubjects() && 
                                                    <PreviousButton text="Math Subject Requires Booking" consume={() => {}} />
                                                } 
                                            </>
                                    }
                                </>
                            }
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}
