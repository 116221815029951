import { useEffect, useState } from "react"
import React from "react"
import { validatePhoneNumber, validateFullName, validateEmail, validatePostcode, validateBookingDate, validateBirthday, validateCVC, validateCardNumber, validateExpiryDate, validateGenericAddress } from '../../utils/validatorUtils';

export const InputTextBox = (props: { 
    placeholder: string; 
    value: string; 
    consume: (val: any) => void; 
    errorMessage?: string; 
    validMessage?: string;
    isDisabled?: boolean;
    englishOnly?: boolean;
}) => {

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        
        if (!props.englishOnly) {
            props.consume(value);
        } else {
            // Regular expression to match only Latin characters, numbers, and basic punctuation
            const latinCharRegex = /^[A-Za-z0-9 .,?!;:'"()@#$%^&*-_+=/\\<>]*$/;
            if (latinCharRegex.test(value) || value === "") {
                props.consume(value);
            }
        }
    };

    return (
        <div className="form-group">
            <input
                type="text"
                className={`form-control required ${props.errorMessage ? 'is-invalid' : ''} ${props.validMessage ? 'is-valid' : ''} `}
                placeholder={props.placeholder}
                value={props.value}
                onChange={(e) => handleChange(e)}
                disabled={props.isDisabled}
            />
            {props.errorMessage && !props.validMessage && <div className="invalid-feedback">
                {props.errorMessage}
            </div> }
            {props.validMessage && !props.errorMessage && <div className="valid-feedback">
                {props.validMessage}
            </div> }
        </div>
    )
}


export const InputCalendarBox = (props: { placeholder: string; value: string; consume: (val: any) => void }) => {
    return (
        <div className="form-group">
            <input
                type="date"
                className="form-control required"
                placeholder={props.placeholder}
                value={props.value}
                onChange={(e) => props.consume(e.target.value)}
            />
        </div>
    )
}


export const InputBirthDate = (props: { 
        placeholder: string; 
        value: string; 
        consume: (val: any) => void; 
        checkIfValid: (val: boolean) => void;
        errorMessage?: string;
    }) => {
    const [inputValue, setInputValue] = React.useState(props.placeholder)
    const [isValid, setIsValid] = React.useState(false)

    React.useEffect(() => {
        if (inputValue === props.placeholder) {
            setIsValid(true)
            return
        }
        const validBirthday = validateBirthday(inputValue)
        setIsValid(validBirthday)
        props.checkIfValid(validBirthday)
        props.consume(inputValue)
    }, [inputValue])

    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 20);

    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 10)

    const formatDate = (date: Date) => {
        let month = '' + (date.getMonth() + 1)
        let day = '' + date.getDate();
        let year = date.getFullYear();

        if (month.length < 2) {
            month = '0' + month 
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        return [year, month, day].join('-')
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        e.preventDefault(); // Prevents manual text input
    };

    return (
        <div className="form-group">
            <input
                type="date"
                className={`form-control required ${props.errorMessage ? 'is-invalid' : ''}`}
                placeholder={props.placeholder}
                value={props.value}
                onChange={(e) => setInputValue(e.target.value)}
                min={formatDate(minDate)}
                max={formatDate(maxDate)}
                onKeyDown={handleKeyDown} // Disables keyboard input
            />
            {(isValid) ? (<></>) :
                (<>
                    <span className="error" style={{}}>
                        DOB cannot be in the future
                    </span>
                </>
                )
            }
            {props.errorMessage && <div className="invalid-feedback">
                {props.errorMessage}
            </div> }
        </div>
    )
}

export const InputBookingDate = (props: {
        errorMessage?: string; 
        placeholder: string; value: 
        string; consume: (val: any) => void; 
        checkIfValid: (val: boolean) => void;
    }) => {
    const [inputValue, setInputValue] = React.useState(props.placeholder)
    const [isValid, setIsValid] = React.useState(false)

    const minDate = new Date();
    minDate.setDate(minDate.getDate());

    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 1)
    maxDate.setMonth(maxDate.getMonth() + 3)

    const formatDate = (date: Date) => {
        let month = '' + (date.getMonth() + 1)
        let day = '' + date.getDate();
        let year = date.getFullYear();

        if (month.length < 2) {
            month = '0' + month 
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        return [year, month, day].join('-')
    }

    React.useEffect(() => {
        if (inputValue === props.placeholder) {
            setIsValid(true)
            return
        }
        const validBookingDate = validateBookingDate(inputValue)
        setIsValid(validBookingDate)
        props.checkIfValid(validBookingDate)
        props.consume(inputValue)
    }, [inputValue])

    
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        e.preventDefault(); // Prevents manual text input
    };

    return (
        <div className="form-group">
            <input
                type="date"
                className={`form-control required ${props.errorMessage ? 'is-invalid' : ''}`}
                placeholder={props.placeholder}
                value={props.value}
                onChange={(e) => setInputValue(e.target.value)}
                min={formatDate(minDate)}
                max={formatDate(maxDate)}
                onKeyDown={handleKeyDown}
            />
            {(isValid) ? (<></>) :
                (<>
                    <span className="error" style={{}}>
                        Booking Date must be in the future
                    </span>
                </>
                )
            }
            {props.errorMessage && <div className="invalid-feedback">
                {props.errorMessage}
            </div> }
        </div>
    )
}


export const InputNumericBox = (props: { placeholder: string; value: string; consume: (val: any) => void }) => {
    return (
        <div className="form-group">
            <input
                type="number"
                className="form-control required"
                placeholder={props.placeholder}
                value={props.value}
                onChange={(e) => props.consume(e.target.value)}
            />
        </div>
    )
}


export const InputEmailTextBox = (props: { 
        placeholder: string; 
        value: string; 
        consume: (val: boolean) => void; 
        updateValue: (val: any) => void 
        errorMessage?: string;
    }) => {
    const [inputValue, setInputValue] = useState(props.placeholder)
    const [isValid, setIsValid] = useState(true)

    React.useEffect(() => {
        if (inputValue === props.placeholder) {
            setIsValid(true)
            return
        }
        const isValid = validateEmail(inputValue.toString().toLowerCase())
        if (inputValue !== props.placeholder && inputValue.trim() !== '') {
            setIsValid(isValid)
        }
        props.consume(isValid)
        props.updateValue(inputValue)
    }
    , [inputValue])

    return (
        <div className="form-group">
            <input
                type="email"
                className={`form-control required ${isValid ? '' : 'error'} ${props.errorMessage ? "is-invalid" : ""}`}
                placeholder={props.placeholder}
                value={props.value}
                onChange={(e: any) => { setInputValue(e.target.value) }}
            />
            {(isValid) ? (<></>) :
                (<>
                    <span className="error" style={{}}>
                        Invalid email format
                    </span>
                </>
                )
            }
            {props.errorMessage && <div className="invalid-feedback">
                {props.errorMessage}
            </div> }
        </div>
    )
}

export const InputPhoneNumberBox = (props: { errorMessage?: string; placeholder: string; value: string; consume: (val: boolean) => void; updateValue: (val: any) => void }) => {
    const [inputValue, setInputValue] = useState(props.placeholder)
    const [isValid, setIsValid] = useState(false)

    const handleInputChange = (e: any) => {
        const value = e.target.value;
        if (value === '' || /^[0-9]+$/.test(value)) {
            setInputValue(value)
        }
    }

    React.useEffect(() => {
        if (inputValue === props.placeholder) {
            setIsValid(true)
            return
        }
        const isValid = validatePhoneNumber(inputValue)
        if (inputValue !== props.placeholder && inputValue.trim() !== '') {
            setIsValid(isValid)
        }
        props.consume(isValid)
        props.updateValue(inputValue)
    }
        , [inputValue])

    return (
        <div className="form-group">
            <input
                type="text"
                name={props.placeholder}
                className={`form-control required ${isValid ? '' : 'error'} ${props.errorMessage ? 'is-invalid' : ''}`}
                placeholder={props.placeholder}
                value={props.value}
                onChange={(e: any) => { handleInputChange(e) }}
            />
            {(isValid) ? (<></>) :
                (<>
                    <span className="error" style={{}}>
                        Invalid Phone Number, must be 10 digits starting with 04
                    </span>
                </>
                )
            }
            
            {props.errorMessage && <div className="invalid-feedback">
                {props.errorMessage}
            </div> }
        </div>
    )
}


export const InputPostcodeBox = (props: { 
        placeholder: string; 
        value: string; 
        consume: (val: boolean) => void; 
        updateValue: (val: any) => void;
        errorMessage?: string;
    }) => {
    const [inputValue, setInputValue] = useState(props.placeholder)
    const [isValid, setIsValid] = useState(false)
    
    const handleInputChange = (e: any) => {
        const value = e.target.value;
        if (value === '' || /^[0-9]+$/.test(value)) {
            setInputValue(value)
        }
    }

    React.useEffect(() => {
        if (inputValue === props.placeholder) {
            setIsValid(true)
            return
        }
        const isValid = validatePostcode(inputValue.toString())
        if (inputValue !== props.placeholder && inputValue.trim() !== '') {
            setIsValid(isValid)
        }
        props.consume(isValid)
        props.updateValue(inputValue)
    }
        , [inputValue])
    return (
        <div className="form-group">
            <input
                type="text"
                className={`form-control required ${isValid ? '' : 'error'} ${props.errorMessage ? 'is-invalid' : ''}`}
                placeholder={props.placeholder}
                value={props.value}
                onChange={(e: any) => { handleInputChange(e) }}
            />
            {(isValid) ? (<></>) :
                (<>
                    <span className="error" style={{}}>
                        Invalid Postcode, Australian Postcodes are 4 digits
                    </span>
                </>
                )
            }
            {props.errorMessage && <div className="invalid-feedback">
                {props.errorMessage}
            </div> }
        </div>
    )
}


export const InputCardNumberBox = (props: { placeholder: string;
    value: string;
    consume: (val: boolean) => void;
    updateValue: (val: any) => void;
    errorMessage?: string;
    }) => {
    const [inputValue, setInputValue] = useState(props.placeholder)
    const [isValid, setIsValid] = useState(false)

    const handleInputChange = (e: any) => {
        const value = e.target.value;
        if (value === '' || /^[0-9]+$/.test(value)) {
            setInputValue(value)
        }
    }

    React.useEffect(() => {
        if (inputValue === props.placeholder) {
            setIsValid(true)
            return
        }
        const isValid = validateCardNumber(inputValue.toString())
        if (inputValue !== props.placeholder && inputValue.trim() !== '') {
            setIsValid(isValid)
        }
        props.consume(isValid)
        props.updateValue(inputValue)
    }
        , [inputValue])
    return (
        <div className="form-group">
            <input
                type="text"
                className={`form-control required ${isValid ? '' : 'error'} ${props.errorMessage ? "is-invalid" : ""}`}
                placeholder={props.placeholder}
                value={props.value}
                onChange={(e: any) => { handleInputChange(e) }}
            />
            {(isValid) ? (<></>) :
                (<>
                    <span className="error" style={{}}>
                        Invalid Card Number, must be 16 digits long
                    </span>
                </>
                )
            }
            {props.errorMessage && <div className="invalid-feedback">
                {props.errorMessage}
            </div> }
        </div>
    )
}


export const InputCVCBox = (props: { 
        placeholder: string; 
        value: string; 
        consume: (val: boolean) => void; 
        updateValue: (val: any) => void;
        errorMessage?: string;
    }) => {
    const [inputValue, setInputValue] = useState(props.placeholder)
    const [isValid, setIsValid] = useState(false)

    const handleInputChange = (e: any) => {
        const value = e.target.value;
        if (value === '' || /^[0-9]+$/.test(value)) {
            setInputValue(value)
        }
    }

    React.useEffect(() => {
        if (inputValue === props.placeholder) {
            setIsValid(true)
            return
        }
        const isValid = validateCVC(inputValue.toString())
        if (inputValue !== props.placeholder && inputValue.trim() !== '') {
            setIsValid(isValid)
        }
        props.consume(isValid)
        props.updateValue(inputValue)
    }
        , [inputValue])
    return (
        <div className="form-group">
            <input
                type="text"
                className={`form-control required ${isValid ? '' : 'error'} ${props.errorMessage ? "is-invalid" : ""}`}
                placeholder={props.placeholder}
                value={props.value}
                onChange={(e: any) => { handleInputChange(e) }}
            />
            {(isValid) ? (<></>) :
                (<>
                    <span className="error" style={{}}>
                        Invalid CVC, must be 3 digits long
                    </span>
                </>
                )
            }
            {props.errorMessage && <div className="invalid-feedback">
                {props.errorMessage}
            </div> }
        </div>
    )
}


export const InputExpiryDateBox = (props: { placeholder: string
        value: string;
        consume: (val: boolean) => void;
        updateValue: (val: any) => void;
        errorMessage?: string;
    }) => {

    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [isValid, setIsValid] = useState(false);

    const handleMonthChange = (e: any) => {
        const monthValue = e.target.value.slice(0, 2); // Restrict to 2 digits
        setMonth(monthValue);
    };

    const handleYearChange = (e: any) => {
        const yearValue = e.target.value.slice(0, 2); // Restrict to 2 digits
        setYear(yearValue);
    };

    React.useEffect(() => {
        const combinedValue = `${month}/${year}`;
        const isValid = validateExpiryDate(combinedValue);
        setIsValid(isValid);
        props.consume(isValid);
        props.updateValue(combinedValue);
    }, [month, year]);

    return (
        <div className="form-group">
            <div 
                style={{ display: 'flex', alignItems: 'center' }}  
                className={`form-control required ${isValid ? '' : 'error'} ${props.errorMessage ? 'is-invalid' : ""}`}
                >
                <input
                    type="text"
                    placeholder="MM"
                    value={month}
                    onChange={handleMonthChange}
                    style={{ marginRight: '10px' }} // Optional, for spacing between inputs
                />
                <input
                    type="text"
                    placeholder="YY"
                    value={year}
                    onChange={handleYearChange}
                />
            </div>
            {!isValid && (month !== '' || year !== '') && (
                <span className="error">
                    Invalid expiry date. Must be of the format MM/YY
                </span>
            )}
            {props.errorMessage && <div className="invalid-feedback">
                {props.errorMessage}
            </div> }
        </div>
    );
};


export const InputAddressBoxWithValidation = (props: { 
    placeholder: string; 
    value: string; 
    consume: (val: any) => void; 
    checkIfValid: (b: boolean) => void;
    errorMessage?: string; 
    englishOnly?: boolean;
}) => {
    const [inputValue, setInputValue] = useState(props.value)
    const [isValid, setIsValid] = useState(true)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        
        if (!props.englishOnly) {
            setInputValue(value)
        } else {
            // Regular expression to match only Latin characters, numbers, and basic punctuation
            const latinCharRegex = /^[A-Za-z0-9 .,?!;:'"()@#$%^&*-_+=/\\<>]*$/;
            if (latinCharRegex.test(value) || value === "") {
                setInputValue(value)
            }
        }
    };

    useEffect(() => {
        const validated = validateGenericAddress(inputValue)
        if (inputValue !== props.placeholder && inputValue.trim() !== '') {
            setIsValid(validated)
        }
        props.checkIfValid(validated)
        props.consume(inputValue)
    }, [inputValue])

    return (
        <div className="form-group">
            <input
                type="text"
                className={`form-control required ${props.errorMessage ? 'is-invalid' : ''} `}
                placeholder={props.placeholder}
                value={props.value}
                onChange={(e) => handleChange(e)}
            />
            {props.errorMessage && <div className="invalid-feedback">
                {props.errorMessage}
            </div> }
            {(isValid) ? <></> :
                <span className="error" style={{}}>
                    Only alphabetical letters, numbers, spaces, - and  ' allowed
                </span>
            }
        </div>
    )
}


export const InputTextBoxWithValidation = (props: { 
    placeholder: string; 
    value: string; 
    consume: (val: any) => void; 
    checkIfValid: (b: boolean) => void;
    errorMessage?: string; 
    englishOnly?: boolean;
}) => {
    const [inputValue, setInputValue] = useState(props.value)
    const [isValid, setIsValid] = useState(true)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        
        if (!props.englishOnly) {
            setInputValue(value)
        } else {
            // Regular expression to match only Latin characters, numbers, and basic punctuation
            const latinCharRegex = /^[A-Za-z0-9 .,?!;:'"()@#$%^&*-_+=/\\<>]*$/;
            if (latinCharRegex.test(value) || value === "") {
                setInputValue(value)
            }
        }
    };

    useEffect(() => {
        const validated = validateGenericAddress(inputValue)
        if (inputValue !== props.placeholder && inputValue.trim() !== '') {
            setIsValid(validated)
        }
        props.checkIfValid(validated)
        props.consume(inputValue)
    }, [inputValue])

    return (
        <div className="form-group">
            <input
                type="text"
                className={`form-control required ${props.errorMessage ? 'is-invalid' : ''} `}
                placeholder={props.placeholder}
                value={props.value}
                onChange={(e) => handleChange(e)}
            />
            {props.errorMessage && <div className="invalid-feedback">
                {props.errorMessage}
            </div> }
            {
                isValid ? <></> :
                <span className="error" style={{}}>
                    Only alphabetical letters, spaces, - and  ' allowed
                </span>
            }
        </div>
    )
}

export const InputNameBox = (props: { 
        placeholder: string; 
        value: string;
        consume: (val: boolean) => void;
        updateValue: (val: any) => void;
        errorMessage?: string;
    }) => {
    const [inputValue, setInputValue] = useState(props.placeholder)
    const [isValid, setIsValid] = useState(false)

    React.useEffect(() => {
        if (inputValue === props.placeholder) {
            setIsValid(true)
            return
        }
        const isValid = validateFullName(inputValue.toString())
        if (inputValue !== props.placeholder && inputValue.trim() !== '') {
            setIsValid(isValid)
        }
        props.consume(isValid)
        props.updateValue(inputValue)
    }
        , [inputValue])
    return (
        <div className="form-group">
            <input
                type="string"
                className={`form-control required ${isValid ? '' : 'error'} ${props.errorMessage ? "is-invalid" : ""}`}
                placeholder={props.placeholder}
                value={props.value}
                onChange={(e: any) => { setInputValue(e.target.value) }}
            />
            {
                isValid ? <></> :
                <span className="error" style={{}}>
                        Names have alphabetical symbols separated by -, ' or space
                </span>
                
            }
            {props.errorMessage && <div className="invalid-feedback">
                {props.errorMessage}
            </div> }
        </div>
    )
}



export const ReadOnlyInputTextBox = (props: { placeholder: string }) => {
    return (
        <div className="form-group">
            <input
                type="text"
                name={props.placeholder}
                className="form-control required"
                placeholder={props.placeholder}
                readOnly
            />
        </div>
    )
}
