import moment from "moment";

type DayToNumber = {
    [day: string]: number
}

export const time24HourTo12Hour = (startTime: string) => {
    return moment(startTime, ["HH:mm:ss"]).format("h:mmA");
}

export const time12HourTo24Hour = (startTime: string) => {
    return moment(startTime, ["h:mmA"]).format("HH:mm:ss");
}

export const dayToNumber : DayToNumber = {
    "Sunday": 0,
    "Monday": 1,
    "Tuesday": 2,
    "Wednesday": 3,
    "Thursday": 4,
    "Friday": 5,
    "Saturday": 6,
}
