export const validatePhoneNumber = (phoneNumber: string) => {
    if (phoneNumber === '') {return false;}

    const phoneNumberValidatorRegex = /^04\d{8}$/
    return phoneNumberValidatorRegex.test(phoneNumber);
}

export const validatePostcode = (postcode: string) => {
    if (postcode === '') {return false;}
    const postcodeValidatorRegex = /^\d{4}$/
    return postcodeValidatorRegex.test(postcode);
}

export const validateEmail = (email: string) => {
    if (email === '') {return false;}
    // const emailValidatorRegex = /^[\w-]+@[\w-]+\.(com|co)(\.[a-z]{2})?$/
    // found this from SO https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression
    const emailValidatorRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    return emailValidatorRegex.test(email);
}

export const validateBookingDate = (inputDate: string) => {
    if (inputDate === '') {return false;}
    const selectedDate = new Date(inputDate);
    const currentDate = new Date();
    selectedDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);
    return selectedDate >= currentDate 
}

export const validateBirthday = (inputDate: string) => {
    const selectedDate = new Date(inputDate);
    const currentDate = new Date();
    return selectedDate < currentDate
}

export const validateCVC = (cvc: string) => {
    if (cvc === '') {return false;}

    const cvcValidatorRegex = /^[0-9]{3}$/
    return cvcValidatorRegex.test(cvc);
}

export const validateGenericName = (name: string) => {
    if (name === '') {return false;}
    const customerNameValidator = /^[a-zA-Z'-\s]+$/
    return customerNameValidator.test(name)
}

export const validateGenericAddress = (name: string) => {
    if (name === '') {return false;}
    const addressNameValidator = /^[a-zA-Z0-9'-\s]+$/
    return addressNameValidator.test(name)
}

export const validateCardNumber = (cardNumber: string) => {
    if (cardNumber === '') {return false;}

    const cardNumberValidatorRegex = /^[0-9]{16}$/
    return cardNumberValidatorRegex.test(cardNumber);
}


export const validateExpiryDate = (expiryDate: string) => {
    if (expiryDate === '') {return false;}

    const expiryDateValidatorRegex = /^(0[1-9]|1[0-2])\/[0-9]{2}$/
    return expiryDateValidatorRegex.test(expiryDate);
}

export const validateFullName = (name: string) => {
    if (name === '') {return false;}
    const nameValidatorRegex = /^([A-Za-z][a-z]*[\s\-'])*([A-Za-z][a-z]*)$/
    return nameValidatorRegex.test(name)
}