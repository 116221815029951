import { PreBookingPage } from "../pages/PreBookingPage";
import { NoEntranceTestRequiredBookingPage } from "../pages/NoEntranceTestRequiredBookingPage";
import { InternalPreBookingPage } from "../pages/InternalPreBookingPage";
export const PREBOOKING_FORM = "/";
export const ENTRANCE_TEST_BOOKING_FORM = "/entrance-test-required-booking-form";
export const NO_ENTRANCE_TEST_BOOKING_FORM = "/no-entrance-test-required-booking-form";
export const INTERNAL_ENTRANCE_TEST_BOOKING_FORM = "/walkin";

export const routes = [
  {
    path: PREBOOKING_FORM,
    component: PreBookingPage
  },
  {
    path: NO_ENTRANCE_TEST_BOOKING_FORM,
    component: NoEntranceTestRequiredBookingPage
  },
  {
    path: INTERNAL_ENTRANCE_TEST_BOOKING_FORM,
    component: InternalPreBookingPage
  },
]